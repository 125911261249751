import React, { ReactElement, useCallback } from 'react';
import {
  TableRow,
  TableBody,
} from '@mui/material';
import {
  ISurvey,
} from 'interfaces/state.d';
import { useModalState } from 'context/modalState';
import { usePrivateOfficeState } from 'context/privateOfficeState';
import { useGlobalState } from 'context/globalState';
// components
import Button from 'components/common/CustomButton';
// styles
import {
  StyledTableContainer,
  StyledTableContent,
  StyledTableHeader as TH,
  StyledTableCell as TC,
  StyledTableRow as TR,
  IDTableCell,
  StatusCell,
} from 'styles/desktop/StyledPersonalOffice';

const Table: React.FC = (): ReactElement => {
  const { modalDispatch } = useModalState();
  const { privateOfficeState } = usePrivateOfficeState();
  const { state } = useGlobalState();

  const claimRewards = useCallback(async (surveyId: number) => {
    if (state.contract && state.contractWithSigner && state.account) {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      await state.contractWithSigner.claimRewards(surveyId);
      state.contract.once(state.contract.filters.Claim(state.account), () => {
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
      });
    }
  }, [state.contract, state.account]);

  return (
    <StyledTableContainer>
      <StyledTableContent>
        <TH>
          <TableRow>
            <TC />
            <TC>Total / Limit participants</TC>
            <TC>Rewards</TC>
            <TC>Your reward</TC>
            <TC>Status</TC>
            <TC />
          </TableRow>
        </TH>
        <TableBody>
          {
          privateOfficeState.completedSurveys?.slice(
            (privateOfficeState.completedSurveysPage - 1) * 7,
            privateOfficeState.completedSurveysPage * 7,
          ).map((survey) => (
            <TR key={survey.idInBlockchain}>
              <IDTableCell>
                <div>
                  ID
                  {survey.idInBlockchain}
                </div>
              </IDTableCell>
              <TC>
                {`${survey.currentVotes} / ${survey.maxVotes}`}
              </TC>
              <TC>
                {`${Math.round(survey.rewardAmount / 0.97)} PROS`}
              </TC>
              <TC>
                {`${(survey.resultStatus === 2 && survey.claimAmount) || '-'} PROS`}
              </TC>
              <TC>
                <StatusCell completed={survey.isRewardsSet}>
                  {
                    survey.isRewardsSet
                      ? 'Completed'
                      : 'In progress'
                  }
                </StatusCell>
              </TC>
              <TC>
                {
                  survey.isRewardsSet
                  && survey.resultStatus === 2
                  && survey.isWon
                  && (
                  <Button
                    disabledButton={typeof survey.claimAmount === 'number'}
                    onClick={() => claimRewards(survey.idInBlockchain)}
                  >
                    Claim
                  </Button>
                  )
                }
              </TC>
            </TR>
          ))
        }
        </TableBody>
      </StyledTableContent>
    </StyledTableContainer>
  );
};

export default Table;
