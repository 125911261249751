import React, { ReactElement } from 'react';
import { useGlobalState } from 'context/globalState';
import { ReactComponent as Telegram } from 'static/images/social_icons/telegram_white.svg';
import { ReactComponent as Twitter } from 'static/images/social_icons/twitter_white.svg';
import { ReactComponent as Medium } from 'static/images/social_icons/medium_white.svg';
// styles
import SocialRow from 'styles/common/StyledSocialsBlock';

const SocialBlock: React.FC = (): ReactElement => {
  const { dispatch } = useGlobalState();

  const redirect = (link: string) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'votes link click',
        amplitudeProps: {
          placement: 'socials',
          link,
        },
      },
    });
  };

  return (
    <SocialRow>
      <span>
        <a
          href="https://t.me/prosperfi"
          onClick={() => redirect('https://t.me/prosperfi')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Telegram />
        </a>
      </span>
      <span>
        <a
          href="https://twitter.com/Prosperpredict"
          onClick={() => redirect('https://twitter.com/Prosperpredict')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter />
        </a>
      </span>
      <span>
        <a
          href="https://medium.com/pooler"
          onClick={() => redirect('https://medium.com/pooler')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Medium />
        </a>
      </span>
    </SocialRow>
  );
};

export default SocialBlock;
