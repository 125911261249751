import React, { ReactElement, useState, useEffect } from 'react';
import {
  Fade,
  Grid,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import { ethers } from 'ethers';
// components
import Button from 'components/common/CustomButton';
// styles
import {
  ModalContainer,
  StyledPaper,
  ModalContent,
  ModalTitle,
  ModalButtonContent,
  ButtonContainer,
} from 'styles/modals/StyledLockModal';

const LockModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  const { state, dispatch } = useGlobalState();
  const { modalsState, modalDispatch } = useModalState();

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_LOCK_MODAL' });
  };

  const lockTokens = async () => {
    if (state.contract && state.tokenContract && state.contractWithSigner) {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      const spender = state.address;
      const amount = ethers.utils.parseEther('2');
      const overrides = {
        gasPrice: ethers.utils.parseUnits('7.5', 'gwei'),
      };
      const approveTx = await state.tokenContract.approve(
        spender,
        amount,
        overrides,
      );
      await approveTx.wait();
      await state.contractWithSigner.lockTokensVoter();
      state.contract.once(state.contract.filters.LockedTokens(state.account), () => {
        dispatch({
          type: 'SET_USER_LOCKED_TOKENS',
          payload: {
            lockedTokens: 2,
          },
        });
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
        modalDispatch({
          type: 'CLOSE_LOCK_MODAL',
        });
      });
    }
  };

  useEffect(() => {
    setOpen(modalsState.openedLockModal);
  }, [modalsState.openedLockModal]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledPaper>
          <ModalContent container justifyContent="center" alignItems="center" direction="column">
            <Grid item>
              <ModalTitle>
                Lock 2 PROS
                <br />
                to participate in survey
              </ModalTitle>
            </Grid>
            <Grid item>
              Just click once
            </Grid>
            <Grid item>
              <ButtonContainer>
                <Button onClick={lockTokens}>
                  <ModalButtonContent>
                    lock and start
                  </ModalButtonContent>
                </Button>
              </ButtonContainer>
            </Grid>
          </ModalContent>
        </StyledPaper>
      </Fade>
    </ModalContainer>
  );
};

export default LockModal;
