import React, { ReactElement } from 'react';
// styles
import {
  StyledBoldtitle,
  TableTitle,
  MobileTitle,
} from 'styles/common/StyledBoldTitle';

interface IProps {
  children: string;
  placement: 'header' | 'table' | 'mobile' | 'mining';
}

const BoldTitle: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    children,
    placement,
  } = props;

  switch (placement) {
    case 'header':
    case 'mining':
      return <StyledBoldtitle>{children}</StyledBoldtitle>;
    case 'table':
      return <TableTitle>{children}</TableTitle>;
    default:
      return <MobileTitle>{children}</MobileTitle>;
  }
};

export default BoldTitle;
