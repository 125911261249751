import styled from 'styled-components';
import { Grid } from '@mui/material';
import {
  Link,
} from 'react-router-dom';

export const StyledOpenedContainer = styled.div`
  width: 1400px; 
`;

export const StyledSurvey = styled.div`
  border-radius: 10px;
  background: white;
  color: #1A1A39;
  width: 100%;
`;

export const SurveyItemHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background: linear-gradient(180deg, #4919D0 0%, #38149F 100%);
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: white;
  border-radius: 10px 10px 0 0;
`;

export const SurveyItemContent = styled.div`
  padding: 20px;

  a {
    text-decoration: none;
  }
`;

export const SurveyItemRowTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #919191;
  text-transform: uppercase;
`;

export const SurveyItemRowContent = styled(Grid)`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const SurveyItemRowContentBlock = styled.div`
  margin-left: 8px;
`;

export const LockButtonContainer = styled.div`
  margin-top: 56px;
  margin-bottom: 127px;
  width: 300px;
  
  button {
    font-weight: 700;
  }
`;

export const StyledLink = styled(Link)<{disabled: boolean}>`
  pointer-events: ${({ disabled }) => disabled && 'none'};
`;
