import styled from 'styled-components';
import leftBg from 'static/images/votes_bg.png';
import rightBg from 'static/images/votes_bg_2.png';

const StyledSurveyContainer = styled.div`
  position: relative;
  width: 1400px;
  background: url(${leftBg}) 0 400px, url(${rightBg}) 100% 100px;
  background-repeat: no-repeat;
`;

export const NftContainer = styled.div<{notDefault?: boolean}>`
  height: ${({ notDefault }) => (notDefault ? 345 : 396)}px;
  width: 345px;
  padding: 8px;
  background: white;
  cursor: ${({ notDefault }) => !notDefault && 'pointer'};
  
  img, iframe {
    height: 100%;
    width: 100%;
    max-height: 329px;
  }
`;

export const VersusBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  font-size: 34px;
  line-height: 39px;
  letter-spacing: 0.02em;
  margin: 0 51px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 329px;
  color: black;
`;

export const ProgressContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 200px;
  width: 100%;  
`;

export const ProgressTitle = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const DeadLineTitle = styled.div`
  font-size: 10px;
  line-height: 11px;
  color: rgba(255, 255, 255, 0.8);
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  background: #1C2370;
  border-radius: 24px;
  width: 100%;
  height: 4px;
`;

export const ProgressContent = styled.div<{progress: number}>`
  position: absolute;
  right: calc(100% - ${(props) => props.progress}%);
  top: 0;
  background: linear-gradient(90deg, #7E38F9 38.83%, #00BCF7 80.93%, #00BCF7 98.99%);
  box-shadow: 0 0 4px #5900F7, 0 0 16px #5900F7;
  border-radius: 24px;
  width: 100%;
  height: 4px;
  transition: all .5s;
`;

export const ProgressBarHint = styled.div<{progress: number}>`
  position: absolute;
  right: calc(100% - ${(props) => {
    if (props.progress < 10) {
      return 10;
    }
    return props.progress;
  }}%);
  margin-top: 8px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SurveyQuestion = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-shadow: 4px 4px 0px #4919CF;
`;

export const OptionsContainer = styled.div`
  max-width: 329px;
  margin-top: 32px;
  
  button {
    margin: 8px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }
`;

export default StyledSurveyContainer;
