import styled from 'styled-components';
import {
  Grid,
} from '@mui/material';

export const StyledAdminContainer = styled.div`
  width: 1400px;
`;

export const ContainerOfTheContainer = styled(Grid)`
  min-height: 500px;
  height: 100%;
`;

export const Content = styled(Grid)`
  height: 100%;
`;

export const LoginForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, .4);
  border-radius: 40px;
  padding: 20px;
  width: 300px;
  height: 300px;
  color: #1A1A39;
`;
