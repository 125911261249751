import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  Grid,
} from '@mui/material';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { useActiveSurveysState } from 'context/activeSurveysState';
import {
  IActiveSurvey,
} from 'interfaces/state.d';
// styles
import {
  StyledOpenedContainer,
} from 'styles/desktop/StyledOpenedSurveys';
// components
import BoldTitle from 'components/common/BoldTitle';
import Surveys from './ActiveSurveysContent';
import LockBlock from './LockBlock';

const ActiveSurveysContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();
  const { activeSurveysState, activeSurveysDispatch } = useActiveSurveysState();

  const getActiveSurveys = useCallback(async () => {
    try {
      const activeSurveysResponse = await axios.get(`${state.baseUrl}/survey/active?sender=${state.account}`);
      if (activeSurveysResponse.data) {
        activeSurveysDispatch({
          type: 'SET_SURVEYS',
          payload: {
            surveys: activeSurveysResponse.data as Array<IActiveSurvey>,
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Something went wrong while was getting active surveys', e);
    }
  }, [state.baseUrl, state.account]);

  useEffect(() => {
    if (!activeSurveysState.surveys) {
      getActiveSurveys().then();
    }
  }, [activeSurveysState.surveys]);

  useEffect(() => {
    activeSurveysDispatch({
      type: 'SET_SURVEYS',
      payload: {
        surveys: null,
      },
    });
  }, [state.account]);

  useEffect(() => {
    if (state.contract) {
      state.contract.on(state.contract.filters.NewSurvey(), () => {
        getActiveSurveys().then();
      });
      state.contract.on(state.contract.filters.NewVote(), () => {
        getActiveSurveys().then();
      });
    }
  }, [state.contract]);

  return (
    <Grid container justifyContent="center">
      <StyledOpenedContainer>
        <BoldTitle placement="header">Discover and Vote for more valuable NFT</BoldTitle>
        <Surveys />
        <LockBlock />
      </StyledOpenedContainer>
    </Grid>
  );
};

export default ActiveSurveysContainer;
