import React, {
  ChangeEvent,
  ReactElement,
  useMemo,
  useRef,
} from 'react';
import {
  TextField,
} from '@mui/material';
import { FileDrop } from 'react-file-drop';
import { useCreateSurveyState } from 'context/createSurveyState';
// styles
import {
  CreationSurveyItemContainer,
  SurveyItemTitle,
  SurveyInputDropDown,
  ImageLoader,
} from 'styles/desktop/StyledPersonalOffice';
import './styledFileDrop.scss';
// components
import PreloadedImage from './PreloadedImage';

const acceptableFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

const NftInput: React.FC = (): ReactElement => {
  // const [showTip, setShowTip] = useState<boolean>(false);
  // const [showUnableLoadPicTip, setShowUnableLoadPicTip] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { createSurveyState, createSurveyDispatch } = useCreateSurveyState();

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    dropImage(files);
  };

  const onTargetClick = () => {
    fileInputRef.current?.click();
  };

  const changeImageLink = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    createSurveyDispatch({ type: 'SET_IMAGE_LINK', payload: { imageLink: value } });
    createSurveyDispatch({ type: 'SET_LOADING_IMAGE', payload: { loadingImage: true } });
    if (!value) {
      createSurveyDispatch({ type: 'SET_LOADING_IMAGE', payload: { loadingImage: false } });
    }
  };

  const dropImage = (files: FileList | null) => {
    if (createSurveyState.imageLink) {
      createSurveyDispatch({
        type: 'SHOW_ERROR_TIP',
        payload: { tipMessage: "You can't drop image with entered link" },
      });
    } else if (files) {
      const targetFile = files[0];
      if (acceptableFileTypes.includes(targetFile.type)) {
        createSurveyDispatch({ type: 'SET_LOADING_IMAGE', payload: { loadingImage: true } });
        createSurveyDispatch({ type: 'SET_IMAGE_FILE', payload: { selectedImageFile: targetFile } });
      } else {
        createSurveyDispatch({
          type: 'SHOW_ERROR_TIP',
          payload: { tipMessage: 'You can load only png, jpg, jpeg or gif image formats' },
        });
      }
    }
  };

  return (
    <CreationSurveyItemContainer>
      <SurveyItemTitle>1. Input your NFT</SurveyItemTitle>
      <SurveyInputDropDown>
        <FileDrop onDrop={dropImage} onTargetClick={onTargetClick}>
          <ImageLoader hidden={!createSurveyState.loadingImage}>Loading...</ImageLoader>
          <PreloadedImage />
        </FileDrop>
      </SurveyInputDropDown>
      <TextField
        value={createSurveyState.imageLink}
        disabled={!!createSurveyState.selectedImage}
        onChange={changeImageLink}
        placeholder="Enter link"
        variant="outlined"
        fullWidth
      />
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        type="file"
        hidden
      />
    </CreationSurveyItemContainer>
  );
};

export default NftInput;
