import styled from 'styled-components';

interface ILogo {
    currency: string | null;
}

export const AccountLogo = styled.div<ILogo>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-right: 12px;
`;

export const CurrencyBalance = styled.span`
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
`;

export const CurrenctAmount = styled.span`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
`;
