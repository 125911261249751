import styled from 'styled-components';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Pagination,
} from '@mui/material';

const StyledPersonalOfficeContainer = styled.div`
  width: 1400px;
`;

export const CreationSurveyContainer = styled.div`
  width: 1164px;
  margin-bottom: 40px;
`;

export const CreationSurveyItemContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(11px);
  border-radius: 16px;
  padding: 28px 24px 24px;
  border: 1px solid #5900F7;
`;

export const SurveyItemTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const SurveyInputDropDown = styled.div`
  height: 120px;
  width: 100%;
  background: white;
  border-radius: 12px;
  margin-top: 40px;
  margin-bottom: 16px;
`;

export const ImageLoader = styled.div`
  color: #0C0C21;
`;

export const StyledUploadedImage = styled.img`
  height: 100%;
  max-width: 100%;
`;

export const StyledUploadedAnimatedImage = styled.iframe`
  height: 100%;
  max-width: 100%;
`;

export const ConditionsTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const SwitcherContainer = styled.div`
  margin-top: 20px;
`;

export const StyledHelperText = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
`;

export const ButtonContent = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
`;

export const ArrowInputToConditionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
`;

export const ArrowConditionsToRewardContainer = styled(ArrowInputToConditionsContainer)`
  justify-content: flex-start;
`;

export const StyledCompletedSurveysContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 88px;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 55px;
`;

export const StyledTableContent = styled(Table)`
  &.MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 16px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    color: #1A1A39;
    text-align: center;
    border: none;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
  }
`;

export const StyledTableHeader = styled(TableHead)`
  ${StyledTableCell} {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: white;
  }
`;

export const StyledTableRow = styled(TableRow)`
  background-color: white;
  color: #00094b;
  height: 77px;

  ${StyledTableCell}:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  ${StyledTableCell}:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const IDTableCell = styled(StyledTableCell)`
  div {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }
`;

export const StatusCell = styled.div<{completed: boolean}>`
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  padding: 8px 12px;
  width: fit-content;
  margin: auto;
  background: ${(props) => (props.completed ? 'rgba(50, 248, 0, 0.46)' : '#FF9900')};
`;

export const StyledPagination = styled(Pagination)`
  margin: 50px 0;
  .css-16zvwxo-MuiButtonBase-root-MuiPaginationItem-root{
    border-radius: 6px;
    color: white;
  }

  .css-1k45dja-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background: linear-gradient(180deg, #FFDD00 0%, #FFC700 100%);
    border-radius: 6px;
    color: black;
    margin: 0 5px;
  }
  
  .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
    background: #5900F7;
    border-radius: 6px;
    color: white;
    width: 30px;
    height: 30px;
  }
  
  .MuiFlatPagination-text {
    color: white;
  }
`;

export const UnlockButtonContainer = styled.div`
  width: 338px;
  margin: auto;
`;

export const UnlockButtonContent = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.04em;
`;

export const StyledCreatedSurveysContainer = styled.div`
  margin-bottom: 80px;
`;

export const NftContainer = styled.div<{nft: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  margin: auto;
  border-radius: 4px;
  background: ${(props) => (
    `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${props.nft}), #C4C4C4`
  )};
  background-size: cover;
  transition: all .5s;
  cursor: pointer;
  
  &:hover {
    transform: scale(2);
    
    svg {
      display: none;
    }
  }
`;

export default StyledPersonalOfficeContainer;
