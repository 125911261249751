import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/globalState';
import SocialsBlock from 'components/common/SocialsBlock';
import { useTranslation } from 'react-i18next';
// styles
import {
  LinkRow,
  SocialContainer,
  ReservedRow,
  StyledFooter,
} from 'styles/desktop/StyledFooter';

const Footer: React.FC = (): ReactElement => {
  const { dispatch } = useGlobalState();
  const { t } = useTranslation(['footer']);

  const redirect = (link: string) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'votes link click',
        amplitudeProps: {
          placement: 'footer',
          link,
        },
      },
    });
  };

  return (
    <StyledFooter>
      <LinkRow container justifyContent="center">
        <Grid item>
          {/* <Link to="information">Information</Link> */}
          {/* <Link to="mining">{t('Mining')}</Link> */}
          {/* <a */}
          {/*  href="https://social.prosper.so" */}
          {/*  onClick={() => redirect('"https://social.prosper.so')} */}
          {/*  target="_blank" */}
          {/*  rel="noopener noreferrer" */}
          {/* > */}
          {/*  Social predictions */}
          {/* </a> */}
          <a
            href="https://platform.prosper.so"
            onClick={() => redirect('https://platform.prosper.so')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Prosper
          </a>
          <a
            href="https://prosper.so/rules"
            onClick={() => redirect('https://prosper.so/rules')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common:Information')}
          </a>
          <a
            href="https://prosper.so/howtostart"
            onClick={() => redirect('https://prosper.so/howtostart')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('How it works')}
          </a>
          <a
            href="https://prosper.so/privacy"
            onClick={() => redirect('https://prosper.so/privacy')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Privacy policy')}
          </a>
          <a
            href="https://prosper.so/terms"
            onClick={() => redirect('https://prosper.so/terms')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Terms of use')}
          </a>
          <a
            href="https://prosper.so/cookies"
            onClick={() => redirect('https://prosper.so/cookies')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Cookies')}
          </a>
        </Grid>
      </LinkRow>
      <SocialContainer container justifyContent="center">
        <SocialsBlock />
      </SocialContainer>
      <ReservedRow container justifyContent="center">
        {`© 2021 Prosper. ${t('All rights reserved')}`}
      </ReservedRow>
    </StyledFooter>
  );
};

export default Footer;
