import React, {
  ChangeEvent,
  ReactElement,
  useMemo,
} from 'react';
import {
  TextField,
  InputAdornment,
} from '@mui/material';
import { useCreateSurveyState } from 'context/createSurveyState';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import { ReactComponent as ProsIcon } from 'static/images/currency_icons/PROS_icon_purple.svg';
import { ethers } from 'ethers';
// styles
import {
  CreationSurveyItemContainer,
  SurveyItemTitle,
  ConditionsTitle,
  StyledHelperText,
  ButtonContainer,
  ButtonContent,
} from 'styles/desktop/StyledPersonalOffice';
// components
import Button from 'components/common/CustomButton';

const SurveyRewards: React.FC = (): ReactElement => {
  const { createSurveyState, createSurveyDispatch } = useCreateSurveyState();
  const { state } = useGlobalState();
  const { modalDispatch } = useModalState();

  const changeReward = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (+value > 0) {
      createSurveyDispatch({
        type: 'SET_SURVEY_REWARD',
        payload: {
          surveyReward: +value,
        },
      });
    }
  };

  const surveyType = useMemo(
    () => (createSurveyState.firstOption && createSurveyState.secondOption
    && createSurveyState.thirdOption && createSurveyState.fourthOption ? 1 : 0),
    [createSurveyState.firstOption, createSurveyState.secondOption,
      createSurveyState.thirdOption, createSurveyState.fourthOption],
  );

  const surveyData = useMemo(
    () => {
      if (surveyType === 1) {
        return `${createSurveyState.firstOption},${createSurveyState.secondOption},${createSurveyState.thirdOption},${createSurveyState.fourthOption}`;
      }
      return '';
    },
    [surveyType, createSurveyState.firstOption, createSurveyState.secondOption,
      createSurveyState.thirdOption, createSurveyState.fourthOption],
  );

  const createSurvey = async () => {
    if ((createSurveyState.imageLink || createSurveyState.selectedImage)
      && createSurveyState.surveyStart && createSurveyState.surveyEnd
    && createSurveyState.surveyMinVotes
      && createSurveyState.surveyMaxVotes && createSurveyState.surveyReward
      && state.contract && state.contractWithSigner && state.tokenContract) {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      const spender = state.address;
      const amount = ethers.utils.parseEther(createSurveyState.surveyReward.toString());
      const overrides = {
        gasPrice: ethers.utils.parseUnits('7.5', 'gwei'),
      };
      const approveTx = await state.tokenContract.approve(
        spender,
        amount,
        overrides,
      );
      await approveTx.wait();
      const rewardAmount = ethers.utils.parseEther(createSurveyState.surveyReward.toString());
      await state.contractWithSigner.createSurvey(
        createSurveyState.imageLink || createSurveyState.selectedImage,
        createSurveyState.surveyStart / 1000,
        createSurveyState.surveyEnd / 1000, createSurveyState.surveyMinVotes,
        createSurveyState.surveyMaxVotes,
        rewardAmount,
        surveyType,
        surveyData,
      );
      state.contract.once(state.contract.filters.NewSurvey(), () => {
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
      });
    }
  };

  return (
    <CreationSurveyItemContainer>
      <SurveyItemTitle>3. Amount of rewards</SurveyItemTitle>
      <ConditionsTitle>Amount of rewards</ConditionsTitle>
      <TextField
        value={createSurveyState.surveyReward}
        onChange={changeReward}
        helperText={
          (
            <StyledHelperText>
              min 1 000 PROS
            </StyledHelperText>
          )
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ProsIcon />
            </InputAdornment>
          ),
        }}
        type="number"
        fullWidth
      />
      <ButtonContainer>
        <Button onClick={createSurvey}>
          <ButtonContent>
            Start the survey
          </ButtonContent>
        </Button>
      </ButtonContainer>
    </CreationSurveyItemContainer>
  );
};

export default SurveyRewards;
