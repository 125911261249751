import React, { ReactElement } from 'react';
import { ReactComponent as BNB } from 'static/images/currency_icons/BNB_icon.svg';
import { useGlobalState } from 'context/globalState';
import { useTranslation } from 'react-i18next';
// icons
import {
  getNetworkComponent,
  getCurrencyName,
} from 'networksHelper';
// styles
import {
  AccountLogo,
  CurrencyBalance,
  CurrenctAmount,
} from 'styles/common/StyledAccount';
import {
  Grid,
} from '@mui/material';

interface IAccountProps {
    currency: string | null;
    amount: Number;
}

const Account: React.FC<IAccountProps> = ({ currency, amount }: IAccountProps): ReactElement => {
  const { t } = useTranslation();
  const { state } = useGlobalState();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <AccountLogo currency={currency}>
          { getNetworkComponent({ networkId: state.currentNetworkId }) }
        </AccountLogo>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <CurrencyBalance>
              {t('Balance')}
              ,
              {' '}
              {currency}
            </CurrencyBalance>
          </Grid>
          <Grid item>
            <CurrenctAmount>
              {amount}
            </CurrenctAmount>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Account;
