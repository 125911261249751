import React, { ReactElement, useCallback } from 'react';
import {
  TableRow,
  TableBody,
} from '@mui/material';
import { useModalState } from 'context/modalState';
import { usePrivateOfficeState } from 'context/privateOfficeState';
import { useGlobalState } from 'context/globalState';
import {
  ICreatorSurvey,
} from 'interfaces/state.d';
// components
import Button from 'components/common/CustomButton';
import SearchIcon from 'static/images/images_as_components/SearchIcon';
// styles
import {
  StyledTableContainer,
  StyledTableContent,
  StyledTableHeader as TH,
  StyledTableCell as TC,
  StyledTableRow as TR,
  IDTableCell,
  StatusCell,
  NftContainer,
} from 'styles/desktop/StyledPersonalOffice';

const Table: React.FC = (p): ReactElement => {
  const { modalDispatch } = useModalState();
  const { privateOfficeState } = usePrivateOfficeState();
  const { state } = useGlobalState();

  const expandNft = (nftUrl: string) => {
    modalDispatch({
      type: 'OPEN_NFT_IMAGE_MODAL',
      payload: {
        nftUrl,
      },
    });
  };

  const claimRewards = useCallback(async (surveyId: number) => {
    if (state.contract && state.contractWithSigner && state.account) {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      await state.contractWithSigner.claimRewards(surveyId);
      state.contract.once(state.contract.filters.Claim(state.account), () => {
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
      });
    }
  }, [state.contract, state.account]);

  const isJson = (string: string) => {
    try {
      const resultObject = JSON.parse(string);
      if (typeof resultObject === 'object') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const getNftPriceResults = (result: string | null): string => {
    if (result) {
      if (isJson(result)) {
        const resultObject = JSON.parse(result);
        if (resultObject.MinPrice) {
          if (resultObject.MaxPrice) {
            return `${resultObject.MinPrice}$ - ${resultObject.MaxPrice}$`;
          }
          return `${resultObject.MinPrice}$ and more`;
        }
        return `${resultObject.MaxPrice}$ and less`;
      }
      return result;
    }
    return '-';
  };

  return (
    <StyledTableContainer>
      <StyledTableContent>
        <TH>
          <TableRow>
            <TC />
            <TC>NFT</TC>
            <TC>Total / Limit participants</TC>
            <TC>Price range</TC>
            <TC>Rewards</TC>
            <TC>Status</TC>
            <TC />
          </TableRow>
        </TH>
        <TableBody>
          {
            privateOfficeState.createdSurveys?.slice(
              (privateOfficeState.createdSurveysPage - 1) * 7,
              privateOfficeState.createdSurveysPage * 7,
            ).map((survey) => (
              <TR key={survey.idInBlockchain}>
                <IDTableCell>
                  <div>
                    ID
                    {survey.idInBlockchain}
                  </div>
                </IDTableCell>
                <TC>
                  <NftContainer nft={survey.nftUrl} onClick={() => expandNft(survey.nftUrl)}>
                    <SearchIcon color="white" />
                  </NftContainer>
                </TC>
                <TC>
                  {`${survey.currentVotes} / ${survey.maxVotes}`}
                </TC>
                <TC>
                  {
                    getNftPriceResults(survey.nftPriceResult)
                  }
                </TC>
                <TC>
                  {`${Math.round(survey.rewardAmount / 0.97)} PROS`}
                </TC>
                <TC>
                  <StatusCell completed={survey.isRewardsSet}>
                    {
                      survey.isRewardsSet
                        ? 'Completed'
                        : 'In progress'
                    }
                  </StatusCell>
                </TC>
                <TC>
                  {
                    survey.isRewardsSet
                    && survey.resultStatus === 3
                    && (
                    <Button
                      disabledButton={typeof survey.claimAmount === 'number'}
                      onClick={() => claimRewards(survey.idInBlockchain)}
                    >
                      Claim
                    </Button>
                    )
                  }
                </TC>
              </TR>
            ))
          }
        </TableBody>
      </StyledTableContent>
    </StyledTableContainer>
  );
};

export default Table;
