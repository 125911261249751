import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
// styles
import {
  StyledPagination,
} from 'styles/desktop/StyledPersonalOffice';

interface IProps {
  total: number;
  changePage: any;
}

const ContentPagination: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    total,
    changePage,
  } = props;

  const totalPages = (rows : number) => Math.ceil(rows / 7);
  return (
    <Grid container justifyContent="center">
      <StyledPagination
        count={totalPages(total)}
        defaultPage={1}
        variant="outlined"
        shape="rounded"
        onChange={(_, offset) => changePage(offset)}
      />
    </Grid>
  );
};

export default ContentPagination;
