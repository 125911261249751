import styled from 'styled-components';
import {
  Modal,
} from '@mui/material';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledNftImage = styled.img`
  max-height: 500px;
  max-width: 1400px;
`;
