import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
// components
import Button from 'components/common/CustomButton';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
// styles
import {
  LockButtonContainer,
} from 'styles/desktop/StyledOpenedSurveys';

const LockBlock: React.FC = (): ReactElement => {
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();

  const unlockTokens = async () => {
    if (state.contract && state.contractWithSigner && state.account) {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      await state.contractWithSigner.unlockVoterTokens();
      state.contract.once(state.contract.filters.UnlockedTokens(state.account), () => {
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
        dispatch({
          type: 'SET_USER_LOCKED_TOKENS',
          payload: {
            lockedTokens: 0,
          },
        });
      });
    }
  };

  const tokensHandler = () => {
    if (state.lockedTokens) {
      unlockTokens();
    } else {
      modalDispatch({ type: 'OPEN_LOCK_MODAL' });
    }
  };

  return (
    <Grid container justifyContent="center">
      <LockButtonContainer>
        <Button secondary onClick={tokensHandler}>
          {
            state.lockedTokens
              ? 'UNLOCK PROS'
              : 'LOCK AND START'
          }
        </Button>
      </LockButtonContainer>
    </Grid>
  );
};

export default LockBlock;
