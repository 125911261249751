import React, { ReactElement } from 'react';
import iconBNB, { ReactComponent as BNB } from 'static/images/currency_icons/BNB_icon.svg';
import iconOKEX, { ReactComponent as OKEX } from 'static/images/currency_icons/OKEX_icon.svg';
import iconPROS, { ReactComponent as PROS } from 'static/images/currency_icons/PROS_icon.svg';
import { INetworkIcons } from 'interfaces/common.d';

const networksDictionary = new Map([
  [56, 'BSC'],
  [5, 'GOERLI'],
  // [66, 'OKEX'],
]);
// if (window.location.hostname === 'localhost') {
//   networksDictionary.set(42, 'KOVAN');
// }

export const networkIcons: INetworkIcons = {
  BSC: iconBNB,
  OKEX: iconOKEX,
};

interface INetworkComponentProps {
  networkId: number | null;
}

export const getCurrencyName = (networkId: number | null) => {
  switch (networkId) {
    default:
      return 'BNB';
  }
};

export const getChainName = (chainId: number) => {
  switch (chainId) {
    case 56:
      return 'Binance Smart Chain';
    case 5:
      return 'Goerli';
    case 66:
      return 'OKExChain';
    default:
      return 'Binance Smart Chain';
  }
};

export const getChainGuideUrl = (chainId: number) => {
  switch (chainId) {
    case 56:
      return 'https://docs.binance.org/smart-chain/wallet/metamask.html#connect-your-metamask-with-binance-smart-chain';
    case 66:
      return 'https://okexchain-docs.readthedocs.io/en/latest/developers/quick-start-for-mainnet.html';
    default:
      return 'https://docs.binance.org/smart-chain/wallet/metamask.html#connect-your-metamask-with-binance-smart-chain';
  }
};

export const getChainRpcUrls = (chainId: number) => {
  switch (chainId) {
    case 56:
      return ['https://bsc-dataseed.binance.org/'];
    case 5:
      return ['https://eth-goerli.g.alchemy.com/v2/9SjVwfBnSmG1O07BFKedCPjwTlrlf3m_'];
    case 66:
      return ['https://exchainrpc.okex.org'];
    default:
      return ['https://bsc-dataseed.binance.org/'];
  }
};

export const getChainBlockExplorerUrls = (chainId: number) => {
  switch (chainId) {
    case 56:
      return ['https://bscscan.com'];
    case 5:
      return ['https://goerli.etherscan.io'];
    case 66:
      return ['https://www.oklink.com/oec/'];
    default:
      return ['https://bscscan.com'];
  }
};

export const getNetworkName = (networkId: number | null) => {
  if (networkId) {
    return networksDictionary.get(networkId) || 'BSC';
  }
  return 'BSC';
};

export const getNetworkId = (networkName: string) => {
  switch (networkName) {
    case 'GOERLI':
      return 5;
    case 'BSC':
      return 56;
    case 'OKEX':
      return 66;
    default:
      return 56;
  }
};

export const getNetworkIconLink = (networkId: number | null) => {
  switch (networkId) {
    case 56:
      return iconBNB;
    case 66:
      return iconOKEX;
    default:
      return iconBNB;
  }
};

export const getNetworkComponent:
  React.FC<INetworkComponentProps> = (props: INetworkComponentProps):ReactElement => {
    const {
      networkId,
    } = props;

    switch (networkId) {
      default:
        return <PROS />;
    }
  };

export const getNetworksNameViaIds = (networks: Array<number>) => networks.map(
  (networkNumber) => networksDictionary.get(networkNumber),
);

export const getNetworksIds = (): Array<number> => [...networksDictionary.keys()];

export const getNetworksNames = (): Array<string> => [...networksDictionary.values()];
