import React, { ReactElement } from 'react';
import {
  Alert,
  Snackbar,
} from '@mui/material';
import { useCreateSurveyState } from 'context/createSurveyState';

const CreateSurveySnackbar: React.FC = (): ReactElement => {
  const { createSurveyState, createSurveyDispatch } = useCreateSurveyState();

  const closeTip = () => {
    createSurveyDispatch({ type: 'CLOSE_ERROR_TIP', payload: {} });
  };

  return (
    <Snackbar
      open={createSurveyState.showTip}
      onClose={closeTip}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Alert variant="filled" severity="error">
        {createSurveyState.tipMessage}
      </Alert>
    </Snackbar>
  );
};

export default CreateSurveySnackbar;
