import React, { ReactElement } from 'react';
import {
  Grid,
} from '@mui/material';
import { useActiveSurveysState } from 'context/activeSurveysState';
import moment from 'moment';
// components
import Survey from './SurveyItem';

const ActiveSurveysContent: React.FC = (): ReactElement => {
  const { activeSurveysState } = useActiveSurveysState();

  return (
    <Grid container spacing={3} justifyContent="center">
      {
      activeSurveysState.surveys?.map((survey) => (
        <Grid item xs={3} container justifyContent="center" key={survey.idInBlockchain}>
          <Survey
            id={survey.id}
            idInBlockchain={survey.idInBlockchain}
            rewardAmount={survey.rewardAmount}
            participants={survey.currentVotes}
            maxParticipants={survey.maxVotes}
            startDate={moment.utc(survey.startDate).toDate()}
            deadline={moment.utc(survey.endDate).toDate()}
          />
        </Grid>
      ))
    }
    </Grid>
  );
};

export default ActiveSurveysContent;
