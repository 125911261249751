import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import {
  NavLink,
  Link,
} from 'react-router-dom';
import { ReactComponent as Burger } from 'static/images/common_icons/burger.svg';

interface ILogo {
    network?: 'BNB' | 'ETH' | undefined;
}

export const HeaderContainer = styled(Grid)`
  background: linear-gradient(#08083E 0%, rgba(14, 14, 30, 0) 100%);
  height: 96px;
  padding: 35px;
`;

export const ContentRow = styled(Grid)`
  width: 1440px;
`;

export const StyledLink = styled(NavLink)`
  margin: 0 32px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  &.active {
    color: #FD0;
    border-bottom: 1px solid #FD0;
    padding-bottom: 2px;
  }
`;

export const StyledOutPlatformLink = styled.a`
  margin: 0 32px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const StyledDefaultLink = styled.a`
  margin: 0 32px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
`;

export const DropDownSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    display: none;
  }
`;

export const DropDownMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root.Mui-selected{
    padding: 0;
    background-color: unset;
    
    a {
      color: black;
      text-decoration: none;
    }
  }
`;

export const DropDownLogo = styled.div<ILogo>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 12px;
    cursor: pointer;
`;

export const StatisticsButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  padding-right: 12px;
  height: 38px;
  width: 141px;
  background: #5900F7;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
`;

export const StatisticsFireContainer = styled.div<{ golden: boolean }>`
  position: relative;
  background: ${(props) => (props.golden ? '#FD0' : '#5900F7')};
  border-radius: 50%;
  width: 38px;
  height: 38px;
  min-width: 38px;
  margin-right: ${isMobile && 12}px;

  svg {
    position: absolute;
    top: 7px;
    right: 7px;
  }
`;

export const LanguageItemLogo = styled.img<{ selected: boolean }>`
  ${(props) => props.selected === true
          && 'border-radius: 50%; box-shadow: 0 0 0 3px rgb(255 160 0 / 50%);'};
`;

export const ManageButtonContainer = styled(Grid)<{newItem?: boolean}>`
  margin-left: 72px !important;
  
  a {
    text-decoration: none;
  }
  
  button {
    position: relative;
    padding: 17px 27px;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.02em;
    
    &:hover {
      background-color: rgba(255, 255, 255, .8);
    }

    ${({ newItem }) => (newItem
      && `&::after {
        content: 'new';
        position: absolute;
        top: -15px;
        right: -15px;
        color: white;
        padding: 0px 4px;
        background: linear-gradient(71.13deg, #7E38F9 37.64%, #00BCF7 89.59%, #00BCF7 111.86%);
        border-radius: 4px;
      }`
  )}
  }
`;
export const StyledBurger = styled(Burger)`
  height: 30px;
  width: 30px;
  margin-left: 30px;

  fill: white;
  cursor: pointer;
`;

export const MenuLink = styled(Link)`
  width: 100%;
  padding: 10px 20px;
`;
