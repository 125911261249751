import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// en
import enCommon from 'locales/en/common.json';
import enHeader from 'locales/en/header.json';
import enFooter from 'locales/en/footer.json';
import enActualPool from 'locales/en/actual_pool.json';
import enChart from 'locales/en/chart.json';
import enClosedPools from 'locales/en/closed_pools.json';
import enModals from 'locales/en/modals.json';
import enMining from 'locales/en/mining.json';
// ru
// import ruCommon from 'locales/ru/common.json';
// import ruHeader from 'locales/ru/header.json';
// import ruFooter from 'locales/ru/footer.json';
// import ruActualPool from 'locales/ru/actual_pool.json';
// import ruChart from 'locales/ru/chart.json';
// import ruClosedPools from 'locales/ru/closed_pools.json';
// import ruModals from 'locales/ru/modals.json';
// import ruMining from 'locales/ru/mining.json';
// // ae
// import aeCommon from 'locales/ae/common.json';
// import aeHeader from 'locales/ae/header.json';
// import aeFooter from 'locales/ae/footer.json';
// import aeActualPool from 'locales/ae/actual_pool.json';
// import aeChart from 'locales/ae/chart.json';
// import aeClosedPools from 'locales/ae/closed_pools.json';
// import aeModals from 'locales/ae/modals.json';
// import aeMining from 'locales/ae/mining.json';
// // de
// import deCommon from 'locales/de/common.json';
// import deHeader from 'locales/de/header.json';
// import deFooter from 'locales/de/footer.json';
// import deActualPool from 'locales/de/actual_pool.json';
// import deChart from 'locales/de/chart.json';
// import deClosedPools from 'locales/de/closed_pools.json';
// import deModals from 'locales/de/modals.json';
// import deMining from 'locales/de/mining.json';
// // es
// import esCommon from 'locales/es/common.json';
// import esHeader from 'locales/es/header.json';
// import esFooter from 'locales/es/footer.json';
// import esActualPool from 'locales/es/actual_pool.json';
// import esChart from 'locales/es/chart.json';
// import esClosedPools from 'locales/es/closed_pools.json';
// import esModals from 'locales/es/modals.json';
// import esMining from 'locales/es/mining.json';
// // ph
// import phCommon from 'locales/ph/common.json';
// import phHeader from 'locales/ph/header.json';
// import phFooter from 'locales/ph/footer.json';
// import phActualPool from 'locales/ph/actual_pool.json';
// import phChart from 'locales/ph/chart.json';
// import phClosedPools from 'locales/ph/closed_pools.json';
// import phModals from 'locales/ph/modals.json';
// import phMining from 'locales/ph/mining.json';
// // fr
// import frCommon from 'locales/fr/common.json';
// import frHeader from 'locales/fr/header.json';
// import frFooter from 'locales/fr/footer.json';
// import frActualPool from 'locales/fr/actual_pool.json';
// import frChart from 'locales/fr/chart.json';
// import frClosedPools from 'locales/fr/closed_pools.json';
// import frModals from 'locales/fr/modals.json';
// import frMining from 'locales/fr/mining.json';
// // id
// import idCommon from 'locales/id/common.json';
// import idHeader from 'locales/id/header.json';
// import idFooter from 'locales/id/footer.json';
// import idActualPool from 'locales/id/actual_pool.json';
// import idChart from 'locales/id/chart.json';
// import idClosedPools from 'locales/id/closed_pools.json';
// import idModals from 'locales/id/modals.json';
// import idMining from 'locales/id/mining.json';
// // it
// import itCommon from 'locales/it/common.json';
// import itHeader from 'locales/it/header.json';
// import itFooter from 'locales/it/footer.json';
// import itActualPool from 'locales/it/actual_pool.json';
// import itChart from 'locales/it/chart.json';
// import itClosedPools from 'locales/it/closed_pools.json';
// import itModals from 'locales/it/modals.json';
// import itMining from 'locales/it/mining.json';
// // jp
// import jpCommon from 'locales/jp/common.json';
// import jpHeader from 'locales/jp/header.json';
// import jpFooter from 'locales/jp/footer.json';
// import jpActualPool from 'locales/jp/actual_pool.json';
// import jpChart from 'locales/jp/chart.json';
// import jpClosedPools from 'locales/jp/closed_pools.json';
// import jpModals from 'locales/jp/modals.json';
// import jpMining from 'locales/jp/mining.json';
// // cn
// import cnCommon from 'locales/cn/common.json';
// import cnHeader from 'locales/cn/header.json';
// import cnFooter from 'locales/cn/footer.json';
// import cnActualPool from 'locales/cn/actual_pool.json';
// import cnChart from 'locales/cn/chart.json';
// import cnClosedPools from 'locales/cn/closed_pools.json';
// import cnModals from 'locales/cn/modals.json';
// import cnMining from 'locales/cn/mining.json';
// // nl
// import nlCommon from 'locales/nl/common.json';
// import nlHeader from 'locales/nl/header.json';
// import nlFooter from 'locales/nl/footer.json';
// import nlActualPool from 'locales/nl/actual_pool.json';
// import nlChart from 'locales/nl/chart.json';
// import nlClosedPools from 'locales/nl/closed_pools.json';
// import nlModals from 'locales/nl/modals.json';
// import nlMining from 'locales/nl/mining.json';
// // pl
// import plCommon from 'locales/pl/common.json';
// import plHeader from 'locales/pl/header.json';
// import plFooter from 'locales/pl/footer.json';
// import plActualPool from 'locales/pl/actual_pool.json';
// import plChart from 'locales/pl/chart.json';
// import plClosedPools from 'locales/pl/closed_pools.json';
// import plModals from 'locales/pl/modals.json';
// import plMining from 'locales/pl/mining.json';
// // pt
// import ptCommon from 'locales/pt/common.json';
// import ptHeader from 'locales/pt/header.json';
// import ptFooter from 'locales/pt/footer.json';
// import ptActualPool from 'locales/pt/actual_pool.json';
// import ptChart from 'locales/pt/chart.json';
// import ptClosedPools from 'locales/pt/closed_pools.json';
// import ptModals from 'locales/pt/modals.json';
// import ptMining from 'locales/pt/mining.json';
// // ro
// import roCommon from 'locales/ro/common.json';
// import roHeader from 'locales/ro/header.json';
// import roFooter from 'locales/ro/footer.json';
// import roActualPool from 'locales/ro/actual_pool.json';
// import roChart from 'locales/ro/chart.json';
// import roClosedPools from 'locales/ro/closed_pools.json';
// import roModals from 'locales/ro/modals.json';
// import roMining from 'locales/ro/mining.json';
// // tr
// import trCommon from 'locales/tr/common.json';
// import trHeader from 'locales/tr/header.json';
// import trFooter from 'locales/tr/footer.json';
// import trActualPool from 'locales/tr/actual_pool.json';
// import trChart from 'locales/tr/chart.json';
// import trClosedPools from 'locales/tr/closed_pools.json';
// import trModals from 'locales/tr/modals.json';
// import trMining from 'locales/tr/mining.json';
// // ua
// import uaCommon from 'locales/ua/common.json';
// import uaHeader from 'locales/ua/header.json';
// import uaFooter from 'locales/ua/footer.json';
// import uaActualPool from 'locales/ua/actual_pool.json';
// import uaChart from 'locales/ua/chart.json';
// import uaClosedPools from 'locales/ua/closed_pools.json';
// import uaModals from 'locales/ua/modals.json';
// import uaMining from 'locales/ua/mining.json';

export const resources = {
  en: {
    common: enCommon,
    header: enHeader,
    footer: enFooter,
    actual_pool: enActualPool,
    chart: enChart,
    closed_pools: enClosedPools,
    modals: enModals,
    mining: enMining,
  },
  // ru: {
  //   common: ruCommon,
  //   header: ruHeader,
  //   footer: ruFooter,
  //   actual_pool: ruActualPool,
  //   chart: ruChart,
  //   closed_pools: ruClosedPools,
  //   modals: ruModals,
  //   mining: ruMining,
  // },
  // ae: {
  //   common: aeCommon,
  //   header: aeHeader,
  //   footer: aeFooter,
  //   actual_pool: aeActualPool,
  //   chart: aeChart,
  //   closed_pools: aeClosedPools,
  //   modals: aeModals,
  //   mining: aeMining,
  // },
  // de: {
  //   common: deCommon,
  //   header: deHeader,
  //   footer: deFooter,
  //   actual_pool: deActualPool,
  //   chart: deChart,
  //   closed_pools: deClosedPools,
  //   modals: deModals,
  //   mining: deMining,
  // },
  // es: {
  //   common: esCommon,
  //   header: esHeader,
  //   footer: esFooter,
  //   actual_pool: esActualPool,
  //   chart: esChart,
  //   closed_pools: esClosedPools,
  //   modals: esModals,
  //   mining: esMining,
  // },
  // ph: {
  //   common: phCommon,
  //   header: phHeader,
  //   footer: phFooter,
  //   actual_pool: phActualPool,
  //   chart: phChart,
  //   closed_pools: phClosedPools,
  //   modals: phModals,
  //   mining: phMining,
  // },
  // fr: {
  //   common: frCommon,
  //   header: frHeader,
  //   footer: frFooter,
  //   actual_pool: frActualPool,
  //   chart: frChart,
  //   closed_pools: frClosedPools,
  //   modals: frModals,
  //   mining: frMining,
  // },
  // id: {
  //   common: idCommon,
  //   header: idHeader,
  //   footer: idFooter,
  //   actual_pool: idActualPool,
  //   chart: idChart,
  //   closed_pools: idClosedPools,
  //   modals: idModals,
  //   mining: idMining,
  // },
  // it: {
  //   common: itCommon,
  //   header: itHeader,
  //   footer: itFooter,
  //   actual_pool: itActualPool,
  //   chart: itChart,
  //   closed_pools: itClosedPools,
  //   modals: itModals,
  //   mining: itMining,
  // },
  // jp: {
  //   common: jpCommon,
  //   header: jpHeader,
  //   footer: jpFooter,
  //   actual_pool: jpActualPool,
  //   chart: jpChart,
  //   closed_pools: jpClosedPools,
  //   modals: jpModals,
  //   mining: jpMining,
  // },
  // cn: {
  //   common: cnCommon,
  //   header: cnHeader,
  //   footer: cnFooter,
  //   actual_pool: cnActualPool,
  //   chart: cnChart,
  //   closed_pools: cnClosedPools,
  //   modals: cnModals,
  //   mining: cnMining,
  // },
  // nl: {
  //   common: nlCommon,
  //   header: nlHeader,
  //   footer: nlFooter,
  //   actual_pool: nlActualPool,
  //   chart: nlChart,
  //   closed_pools: nlClosedPools,
  //   modals: nlModals,
  //   mining: nlMining,
  // },
  // pl: {
  //   common: plCommon,
  //   header: plHeader,
  //   footer: plFooter,
  //   actual_pool: plActualPool,
  //   chart: plChart,
  //   closed_pools: plClosedPools,
  //   modals: plModals,
  //   mining: plMining,
  // },
  // pt: {
  //   common: ptCommon,
  //   header: ptHeader,
  //   footer: ptFooter,
  //   actual_pool: ptActualPool,
  //   chart: ptChart,
  //   closed_pools: ptClosedPools,
  //   modals: ptModals,
  //   mining: ptMining,
  // },
  // ro: {
  //   common: roCommon,
  //   header: roHeader,
  //   footer: roFooter,
  //   actual_pool: roActualPool,
  //   chart: roChart,
  //   closed_pools: roClosedPools,
  //   modals: roModals,
  //   mining: roMining,
  // },
  // tr: {
  //   common: trCommon,
  //   header: trHeader,
  //   footer: trFooter,
  //   actual_pool: trActualPool,
  //   chart: trChart,
  //   closed_pools: trClosedPools,
  //   modals: trModals,
  //   mining: trMining,
  // },
  // ua: {
  //   common: uaCommon,
  //   header: uaHeader,
  //   footer: uaFooter,
  //   actual_pool: uaActualPool,
  //   chart: uaChart,
  //   closed_pools: uaClosedPools,
  //   modals: uaModals,
  //   mining: uaMining,
  // },
} as const;

export const defaultNS = 'common';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: {
      'ru-RU': ['ru'],
      'ar-ae': ['ae'],
      default: ['en'],
    },
    ns: ['common', 'header', 'footer', 'actual_pool', 'chart', 'closed_pools', 'modals'],
    defaultNS,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
