import React, { ReactElement } from 'react';
import { usePrivateOfficeState } from 'context/privateOfficeState';
// components
import Pagination from 'components/desktop/personal_office/ContentPagination';
import Table from './Table';

const TableContainer: React.FC = (): ReactElement => {
  const { privateOfficeState, privateOfficeDispatch } = usePrivateOfficeState();

  const changePage = (newPage: number) => {
    privateOfficeDispatch({
      type: 'SET_CREATED_SURVEYS_PAGE',
      payload: {
        createdSurveysPage: newPage,
      },
    });
  };

  return (
    <>
      <Table />
      <Pagination
        total={
          privateOfficeState.createdSurveys
            ? privateOfficeState.createdSurveys.length
            : 0
        }
        changePage={changePage}
      />
    </>
  );
};

export default TableContainer;
