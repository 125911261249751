/* eslint-disable no-shadow */
import React, {
  ReactElement, useState, useEffect, useCallback,
} from 'react';
// styles
import StyledCount, {
  StyledCountItem,
} from 'styles/common/StyledCountDown';

interface IProps {
    deadline: Date;
}

// let intervalId: ReturnType<typeof setInterval> | null = null;

const CountDown: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    deadline,
  } = props;

  const [days, setDays] = useState<string>('0');
  const [hours, setHours] = useState<string>('00');
  const [minutes, setMinutes] = useState<string>('00');
  const [seconds, setSeconds] = useState<string>('00');
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

  const getTime = useCallback(() => {
    const today = new Date();
    const countDownDate: number = deadline.getTime();
    const now: number = today.getTime();
    const diff: number = countDownDate - now;
    const maxDaysInCurrentMonth = new Date(today.getMonth(), today.getFullYear(), 0).getDate();
    const days: number = Math.floor(
      (diff % (1000 * 60 * 60 * 24 * maxDaysInCurrentMonth)) / (1000 * 60 * 60 * 24),
    );
    const hours: number = Math.floor(
      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes: number = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds: number = Math.floor((diff % (1000 * 60)) / 1000);

    if (diff >= 0) {
      return {
        days: days.toString(),
        hours: hours < 10 ? `0${hours}` : hours.toString(),
        minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
        seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
      };
    }

    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }, [deadline]);

  const setCurrentTime = useCallback(() => {
    const {
      days,
      hours,
      minutes,
      seconds,
    } = getTime();

    setDays(days || '0');
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [getTime]);

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setCurrentTime();
    setIntervalId(setInterval(() => setCurrentTime(), 1000));

    // clearing timeout on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [deadline, setCurrentTime]);

  return (
    <StyledCount>
      {
        +days > 0
        && (
          <StyledCountItem time={days}>
            {`${days} ${+days === 1 ? 'day' : 'days'} : `}
          </StyledCountItem>
        )
      }
      <StyledCountItem time={hours}>
        {`${hours} : `}
      </StyledCountItem>
      <StyledCountItem time={minutes}>
        {`${minutes} : `}
      </StyledCountItem>
      <StyledCountItem time={seconds}>
        {seconds}
      </StyledCountItem>
    </StyledCount>
  );
};

export default CountDown;
