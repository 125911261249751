import React, { createContext, useContext, useReducer } from 'react';
import {
  IModalState,
} from 'interfaces/state.d';

type ActionType = 'OPEN_WRONG_NETWORK_MODAL'
  | 'CLOSE_WRONG_NETWORK_MODAL'
  | 'OPEN_LOADING_MODAL'
  | 'CLOSE_LOADING_MODAL'
  | 'OPEN_LOCK_MODAL'
  | 'CLOSE_LOCK_MODAL'
  | 'OPEN_NFT_IMAGE_MODAL'
  | 'CLOSE_NFT_IMAGE_MODAL'
  | 'OPEN_FINISHED_SURVEY'
  | 'CLOSE_FINISHED_SURVEY'
  | 'OPEN_SENT_SURVEY_REQUEST'
  | 'CLOSE_SENT_SURVEY_REQUEST';
type PayloadType = any;
interface IAction {
    type: ActionType;
    payload?: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const ModalContext = createContext<{modalsState: IModalState;
  modalDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (modalsState: IModalState, action: IAction): IModalState => {
  switch (action.type) {
    case 'OPEN_WRONG_NETWORK_MODAL':
      return {
        ...modalsState,
        openedWrongNetworkModal: true,
      };
    case 'CLOSE_WRONG_NETWORK_MODAL':
      return {
        ...modalsState,
        openedWrongNetworkModal: false,
      };
    case 'OPEN_LOADING_MODAL':
      return {
        ...modalsState,
        openedLoadingModal: true,
      };
    case 'CLOSE_LOADING_MODAL':
      return {
        ...modalsState,
        openedLoadingModal: false,
      };
    case 'OPEN_LOCK_MODAL':
      return {
        ...modalsState,
        openedLockModal: true,
      };
    case 'CLOSE_LOCK_MODAL':
      return {
        ...modalsState,
        openedLockModal: false,
      };
    case 'OPEN_NFT_IMAGE_MODAL':
      return openNftImageModal(modalsState, action.payload.nftUrl);
    case 'CLOSE_NFT_IMAGE_MODAL':
      return closeNftImageModal(modalsState);
    case 'OPEN_FINISHED_SURVEY':
      return {
        ...modalsState,
        openedFinishedSurveyModal: true,
      };
    case 'CLOSE_FINISHED_SURVEY':
      return {
        ...modalsState,
        openedFinishedSurveyModal: false,
      };
    case 'OPEN_SENT_SURVEY_REQUEST':
      return {
        ...modalsState,
        openedSentSurveyRequest: true,
      };
    case 'CLOSE_SENT_SURVEY_REQUEST':
      return {
        ...modalsState,
        openedSentSurveyRequest: false,
      };
    default:
      return initialState;
  }
};

const openNftImageModal = (state: IModalState, nftUrl: string): IModalState => ({
  ...state,
  openedNftImageModal: true,
  nftImageUrl: nftUrl,
});

const closeNftImageModal = (state: IModalState): IModalState => ({
  ...state,
  openedNftImageModal: false,
  nftImageUrl: '',
});

const initialState: IModalState = {
  openedWrongNetworkModal: false,
  openedLoadingModal: false,
  openedLockModal: false,
  openedNftImageModal: false,
  openedFinishedSurveyModal: false,
  openedSentSurveyRequest: false,
  nftImageUrl: '',
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalsState, modalDispatch] = useReducer(stateReducer, initialState);

  return (
    <ModalContext.Provider value={{ modalsState, modalDispatch }}>
      { children }
    </ModalContext.Provider>
  );
};

export const useModalState = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error('useModalState must be used in a modal state provider');

  return context;
};

export default StateProvider;
