import React, {
  ReactElement,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { useGlobalState } from 'context/globalState';
import axios from 'axios';
import { IActiveSurvey } from 'interfaces/state.d';
// components
import DefaultSurvey from './DefaultSurvey';
import FourOptionsSurvey from './FourOptionsSurvey';

interface IParams {
  id: string;
  blockchainId: string;
}

const SurveySwitcher: React.FC = (): ReactElement => {
  const {
    id,
  }: IParams = useParams();
  const [surveyData, setSurveyData] = useState<IActiveSurvey | null>(null);
  const { state } = useGlobalState();

  const getSurveyData = useCallback(async () => {
    try {
      const surveyDataResponse = await axios.get(`${state.baseUrl}/survey/${id}`);

      if (surveyDataResponse.data) {
        return surveyDataResponse.data;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while getting survey data ', e);
    }

    return {};
  }, [state.baseUrl, id]);

  const surveyOptions = useMemo(
    () => surveyData?.data?.split(',') || ['0 - 100', '100 - 1000', '1000 - 5000', '5000 - 10000'],
    [surveyData],
  );

  const survey = useMemo(
    () => {
      if (surveyData === null) {
        return (
          <div>loading</div>
        );
      }

      switch (surveyData.type) {
        case 0:
          return <DefaultSurvey />;
        case 1:
          return <FourOptionsSurvey options={surveyOptions} nftUrl={surveyData.nftUrl!} />;
        default:
          return <DefaultSurvey />;
      }
    },
    [surveyData],
  );

  useEffect(() => {
    if (surveyData === null) {
      getSurveyData().then(
        (data) => setSurveyData(data),
      );
    }
  }, [surveyData]);

  return (
    survey
  );
};

export default SurveySwitcher;
