import React, { ReactElement, useMemo } from 'react';
import {
  Link,
} from 'react-router-dom';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
// components
import CountDown from 'components/common/CountDown';
import Button from 'components/common/CustomButton';
// styles
import {
  StyledSurvey,
  SurveyItemHeader,
  SurveyItemContent,
  SurveyItemRowTitle,
  SurveyItemRowContent,
  SurveyItemRowContentBlock,
  StyledLink,
} from 'styles/desktop/StyledOpenedSurveys';
// icons
import { ReactComponent as Pros } from 'static/images/currency_icons/PROS_icon_purple.svg';
import { ReactComponent as User } from 'static/images/common_icons/user_icon_purple.svg';
import { ReactComponent as Timer } from 'static/images/common_icons/timer_icon_purple.svg';

interface IProps {
  id: number;
  idInBlockchain: number;
  rewardAmount: number;
  participants: number;
  maxParticipants: number;
  startDate: Date;
  deadline: Date;
}

const SurveyItem: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    id,
    idInBlockchain,
    rewardAmount,
    participants,
    maxParticipants,
    startDate,
    deadline,
  } = props;

  const { state } = useGlobalState();
  const { modalDispatch } = useModalState();

  const openLockModal = () => {
    modalDispatch({ type: 'OPEN_LOCK_MODAL' });
  };

  const initRewardAmount = useMemo(
    () => Math.round(rewardAmount / 0.97),
    [rewardAmount],
  );

  const startButton = useMemo(() => (
    state.lockedTokens
      ? (
        <StyledLink to={`surveys/${id}/${idInBlockchain}`} disabled={new Date() < startDate}>
          <Button disabledButton={new Date() < startDate}>
            START
          </Button>
        </StyledLink>
      )
      : <Button onClick={openLockModal}>START</Button>
  ), [state.lockedTokens]);

  return (
    <StyledSurvey>
      <SurveyItemHeader>
        {`SURVEY ID ${idInBlockchain}`}
      </SurveyItemHeader>
      <SurveyItemContent>
        <div>
          <SurveyItemRowTitle>Rewards</SurveyItemRowTitle>
          <SurveyItemRowContent container alignItems="center">
            <Pros />
            <SurveyItemRowContentBlock>
              {initRewardAmount}
            </SurveyItemRowContentBlock>
          </SurveyItemRowContent>
        </div>
        <div>
          <SurveyItemRowTitle>Participants</SurveyItemRowTitle>
          <SurveyItemRowContent container alignItems="center">
            <User />
            <SurveyItemRowContentBlock>
              {participants}
              /
              {maxParticipants}
            </SurveyItemRowContentBlock>
          </SurveyItemRowContent>
        </div>
        <SurveyItemRowContent container alignItems="center">
          <Timer width={20} height={20} />
          <SurveyItemRowContentBlock>
            <CountDown deadline={deadline} />
          </SurveyItemRowContentBlock>
        </SurveyItemRowContent>
        {startButton}
      </SurveyItemContent>
    </StyledSurvey>
  );
};

export default SurveyItem;
