import React, { ReactElement } from 'react';
import {
  StyledUploadedImage,
  StyledUploadedAnimatedImage,
} from 'styles/desktop/StyledPersonalOffice';
import { ReactComponent as DropFileIcon } from 'static/images/common_icons/file_drop_icon.svg';
import { useCreateSurveyState } from 'context/createSurveyState';

const PreloadedImage: React.FC = (): ReactElement => {
  const { createSurveyState, createSurveyDispatch } = useCreateSurveyState();

  const loadedImageHandler = () => {
    createSurveyDispatch({ type: 'SET_LOADING_IMAGE', payload: { loadingImage: false } });
  };

  if (createSurveyState.imageLink || createSurveyState.selectedImage) {
    return (
      <>
        {
          createSurveyState.imageLink.includes('generator.artblocks.io')
            ? (
              <StyledUploadedAnimatedImage
                title="nft"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                sandbox="allow-scripts"
                src={createSurveyState.imageLink}
                onLoad={loadedImageHandler}
              />
            )
            : (
              <StyledUploadedImage
                hidden={createSurveyState.loadingImage}
                src={createSurveyState.imageLink || createSurveyState.selectedImage}
                alt="nft"
                onLoad={loadedImageHandler}
              />
            )
        }
      </>
    );
  }
  return <DropFileIcon />;
};

export default PreloadedImage;
