import React, { ReactElement } from 'react';
// styles
import StyledOpenedSurveysContainer from 'styles/mobile/StyledOpenedSurveys';

const OpenedSurveysContainer: React.FC = (): ReactElement => (
  <StyledOpenedSurveysContainer>
    We are currently working on mobile version.
    <br />
    Stay tuned!
  </StyledOpenedSurveysContainer>
);

export default OpenedSurveysContainer;
