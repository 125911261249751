import React, {
  ChangeEvent,
  ReactElement,
} from 'react';
import {
  TextField,
} from '@mui/material';
import { useCreateSurveyState } from 'context/createSurveyState';
// styles
import {
  ConditionsTitle,
} from 'styles/desktop/StyledPersonalOffice';

const FourChoiceSurvey: React.FC = (): ReactElement => {
  const { createSurveyState, createSurveyDispatch } = useCreateSurveyState();

  const changeOption = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    optionIndex: number,
  ) => {
    const { value } = event.target;
    createSurveyDispatch({
      type: 'SET_OPTION',
      payload: {
        optionIndex,
        option: value,
      },
    });
  };

  const changeQuestion = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    createSurveyDispatch({
      type: 'SET_QUESTION',
      payload: {
        question: value,
      },
    });
  };

  return (
    <>
      <ConditionsTitle>First option</ConditionsTitle>
      <TextField
        placeholder=""
        value={createSurveyState.firstOption}
        onChange={(e) => changeOption(e, 0)}
        fullWidth
      />
      <ConditionsTitle>Second option</ConditionsTitle>
      <TextField
        placeholder=""
        value={createSurveyState.secondOption}
        onChange={(e) => changeOption(e, 1)}
        fullWidth
      />
      <ConditionsTitle>Third option</ConditionsTitle>
      <TextField
        placeholder=""
        value={createSurveyState.thirdOption}
        onChange={(e) => changeOption(e, 2)}
        fullWidth
      />
      <ConditionsTitle>Fourth option</ConditionsTitle>
      <TextField
        placeholder=""
        value={createSurveyState.fourthOption}
        onChange={(e) => changeOption(e, 3)}
        fullWidth
      />
      {/* <ConditionsTitle>Question</ConditionsTitle> */}
      {/* <TextField */}
      {/*  placeholder="" */}
      {/*  value={createSurveyState.question} */}
      {/*  onChange={changeQuestion} */}
      {/*  fullWidth */}
      {/* /> */}
    </>
  );
};

export default FourChoiceSurvey;
