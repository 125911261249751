import React, { ReactElement, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { usePrivateOfficeState } from 'context/privateOfficeState';
// styles
import {
  StyledCompletedSurveysContainer,
} from 'styles/desktop/StyledPersonalOffice';
// components
import BoldTitle from 'components/common/BoldTitle';
import Table from './TableContainer';

const CompletedSurveysContainer: React.FC = (): ReactElement => {
  const { privateOfficeState, privateOfficeDispatch } = usePrivateOfficeState();
  const { state } = useGlobalState();

  const getCompletedSurveys = useCallback(async () => {
    try {
      const completedSurveysResponse = await axios.get(`${state.baseUrl}/user/${state.account}/participated-surveys`);
      if (completedSurveysResponse.data) {
        privateOfficeDispatch({
          type: 'SET_COMPLETED_SURVEYS',
          payload: {
            completedSurveys: completedSurveysResponse.data,
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Something went wrong while getting user completed surveys');
    }
  }, [state.baseUrl, state.account]);

  useEffect(() => {
    if (!privateOfficeState.completedSurveys && state.account) {
      getCompletedSurveys().then();
    }
  }, [privateOfficeState.completedSurveys, state.account]);

  useEffect(() => {
    if (state.contract) {
      state.contract.on(state.contract.filters.NewVote(), () => {
        getCompletedSurveys().then();
      });
      state.contract.on(state.contract.filters.RewardsAreSet(), () => {
        getCompletedSurveys().then();
      });
      state.contract.on(state.contract.filters.Claim(state.account), () => {
        getCompletedSurveys().then();
      });
    }
  }, [state.contract]);

  return (
    <StyledCompletedSurveysContainer>
      <BoldTitle placement="table">Completed surveys</BoldTitle>
      <Table />
    </StyledCompletedSurveysContainer>
  );
};

export default CompletedSurveysContainer;
