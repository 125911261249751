/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, ReactElement } from 'react';
import {
  DateTimePicker,
} from '@mui/lab';
import {
  TextField,
} from '@mui/material';
import { useCreateSurveyState } from 'context/createSurveyState';
// styles
import {
  ConditionsTitle,
} from 'styles/desktop/StyledPersonalOffice';

const CompareSurvey: React.FC = (): ReactElement => {
  const { createSurveyState, createSurveyDispatch } = useCreateSurveyState();

  const changeStartDate = (value: Date | null) => {
    if (value) {
      createSurveyDispatch({
        type: 'SET_SURVEY_START',
        payload: {
          surveyStart: new Date(value).getTime(),
        },
      });
    }
  };

  const changeEndDate = (value: Date | null) => {
    if (value) {
      createSurveyDispatch({
        type: 'SET_SURVEY_END',
        payload: {
          surveyEnd: new Date(value).getTime(),
        },
      });
    }
  };

  const changeMinVotes = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (+value > 0) {
      createSurveyDispatch({
        type: 'SET_SURVEY_MIN_VOTES',
        payload: {
          surveyMinVotes: +value,
        },
      });
    }
  };

  const changeMaxVotes = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (+value > 0) {
      createSurveyDispatch({
        type: 'SET_SURVEY_MAX_VOTES',
        payload: {
          surveyMaxVotes: +value,
        },
      });
    }
  };

  return (
    <>
      <ConditionsTitle>Date of the start</ConditionsTitle>
      <DateTimePicker
        renderInput={(props) => <TextField fullWidth {...props} />}
        value={new Date(createSurveyState.surveyStart)}
        onChange={changeStartDate}
        inputFormat="DD.MM.yyyy HH:mm"
        ampm={false}
        disablePast
      />
      <ConditionsTitle>Date of the end</ConditionsTitle>
      <DateTimePicker
        renderInput={(props) => <TextField fullWidth {...props} />}
        value={new Date(createSurveyState.surveyEnd)}
        onChange={changeEndDate}
        inputFormat="DD.MM.yyyy HH:mm"
        ampm={false}
        disablePast
      />
      <ConditionsTitle>Min number of participants</ConditionsTitle>
      <TextField
        placeholder="10"
        type="number"
        value={createSurveyState.surveyMinVotes}
        onChange={changeMinVotes}
        fullWidth
      />
      <ConditionsTitle>Limit of participants</ConditionsTitle>
      <TextField
        placeholder="100"
        type="number"
        value={createSurveyState.surveyMaxVotes}
        onChange={changeMaxVotes}
        fullWidth
      />
    </>
  );
};

export default CompareSurvey;
