import React, { createContext, useContext, useReducer } from 'react';
import {
  IPrivateOfficeState,
  ISurvey,
  ICreatorSurvey,
} from 'interfaces/state.d';

type ActionType = 'SET_COMPLETED_SURVEYS'
  | 'SET_CREATED_SURVEYS'
  | 'SET_COMPLETED_SURVEYS_PAGE'
  | 'SET_CREATED_SURVEYS_PAGE';
type PayloadType = any;
interface IAction {
  type: ActionType;
  payload?: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const PrivateOfficeContext = createContext<{privateOfficeState: IPrivateOfficeState;
  privateOfficeDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (privateOfficeState: IPrivateOfficeState, action: IAction) => {
  const {
    completedSurveys,
    createdSurveys,
    completedSurveysPage,
    createdSurveysPage,
  } = action.payload;

  switch (action.type) {
    case 'SET_COMPLETED_SURVEYS':
      return setCompletedSurveys(privateOfficeState, completedSurveys);
    case 'SET_CREATED_SURVEYS':
      return setCreatedSurveys(privateOfficeState, createdSurveys);
    case 'SET_COMPLETED_SURVEYS_PAGE':
      return setCompletedSurveysPage(privateOfficeState, completedSurveysPage);
    case 'SET_CREATED_SURVEYS_PAGE':
      return setCreatedSurveysPage(privateOfficeState, createdSurveysPage);
    default:
      return privateOfficeState;
  }
};

const setCompletedSurveys = (state: IPrivateOfficeState,
  completedSurveys: Array<ISurvey>): IPrivateOfficeState => {
  const sortedCompletedSurveys = completedSurveys.sort(
    (a, b) => b.idInBlockchain - a.idInBlockchain,
  );

  return {
    ...state,
    completedSurveys: sortedCompletedSurveys,
  };
};

const setCreatedSurveys = (state: IPrivateOfficeState,
  createdSurveys: Array<ICreatorSurvey>): IPrivateOfficeState => {
  const sortedCreatedSurveys = createdSurveys.sort(
    (a, b) => b.idInBlockchain - a.idInBlockchain,
  );

  return {
    ...state,
    createdSurveys: sortedCreatedSurveys,
  };
};

const setCompletedSurveysPage = (state: IPrivateOfficeState,
  newPage: number): IPrivateOfficeState => ({
  ...state,
  completedSurveysPage: newPage,
});

const setCreatedSurveysPage = (state: IPrivateOfficeState,
  newPage: number): IPrivateOfficeState => ({
  ...state,
  createdSurveysPage: newPage,
});

const initialState: IPrivateOfficeState = {
  completedSurveys: null,
  completedSurveysPage: 1,
  createdSurveys: null,
  createdSurveysPage: 1,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [privateOfficeState, privateOfficeDispatch] = useReducer(stateReducer, initialState);

  return (
    <PrivateOfficeContext.Provider value={{ privateOfficeState, privateOfficeDispatch }}>
      { children }
    </PrivateOfficeContext.Provider>
  );
};

export const usePrivateOfficeState = () => {
  const context = useContext(PrivateOfficeContext);

  if (!context) throw new Error('usePrivateOfficeState must be used in a private office state provider');

  return context;
};

export default StateProvider;
