import React, { ReactElement, useState, useEffect } from 'react';
import {
  Fade,
  Grid,
} from '@mui/material';
import {
  Link,
} from 'react-router-dom';
import { useModalState } from 'context/modalState';
// components
import Button from 'components/common/CustomButton';
// styles
import {
  ModalContainer,
  StyledPaper,
  ModalContent,
  ModalTitle,
  ModalButtonContent,
  ButtonContainer,
} from 'styles/modals/StyledFinishedSurveyModal';

const SentSurveyRequest: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  const { modalsState, modalDispatch } = useModalState();

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_SENT_SURVEY_REQUEST' });
  };

  useEffect(() => {
    setOpen(modalsState.openedSentSurveyRequest);
  }, [modalsState.openedSentSurveyRequest]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledPaper>
          <ModalContent container justifyContent="center" alignItems="center" direction="column">
            <Grid item>
              <ModalTitle>Thank you for your request</ModalTitle>
            </Grid>
            <Grid item>
              We will contact with you soon.
            </Grid>
          </ModalContent>
        </StyledPaper>
      </Fade>
    </ModalContainer>
  );
};

export default SentSurveyRequest;
