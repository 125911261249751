import React, { createContext, useContext, useReducer } from 'react';
import {
  ICreateSurveyState,
} from 'interfaces/state.d';

type ActionType = 'SET_IMAGE_LINK'
  | 'SET_IMAGE_FILE'
  | 'SHOW_ERROR_TIP'
  | 'CLOSE_ERROR_TIP'
  | 'SET_LOADING_IMAGE'
  | 'SET_SURVEY_START'
  | 'SET_SURVEY_END'
  | 'SET_SURVEY_MIN_VOTES'
  | 'SET_SURVEY_MAX_VOTES'
  | 'SET_SURVEY_REWARD'
  | 'SET_OPTION'
  | 'SET_QUESTION';
type PayloadType = any;
interface IAction {
  type: ActionType;
  payload?: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const CreateSurveyContext = createContext<{createSurveyState: ICreateSurveyState;
  createSurveyDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (createSurveyState: ICreateSurveyState, action: IAction) => {
  const {
    imageLink,
    selectedImageFile,
    tipMessage,
    loadingImage,
    surveyStart,
    surveyEnd,
    surveyMinVotes,
    surveyMaxVotes,
    surveyReward,
    option,
    optionIndex,
    question,
  } = action.payload;

  switch (action.type) {
    case 'SET_IMAGE_LINK':
      return setImageLink(createSurveyState, imageLink);
    case 'SET_IMAGE_FILE':
      return setImageFile(createSurveyState, selectedImageFile);
    case 'SHOW_ERROR_TIP':
      return showErrorTip(createSurveyState, tipMessage);
    case 'CLOSE_ERROR_TIP':
      return closeErrorTip(createSurveyState);
    case 'SET_LOADING_IMAGE':
      return setLoadingImage(createSurveyState, loadingImage);
    case 'SET_SURVEY_START':
      return setSurveyStart(createSurveyState, surveyStart);
    case 'SET_SURVEY_END':
      return setSurveyEnd(createSurveyState, surveyEnd);
    case 'SET_SURVEY_MIN_VOTES':
      return setSurveyMinVotes(createSurveyState, surveyMinVotes);
    case 'SET_SURVEY_MAX_VOTES':
      return setSurveyMaxVotes(createSurveyState, surveyMaxVotes);
    case 'SET_SURVEY_REWARD':
      return setSurveyReward(createSurveyState, surveyReward);
    case 'SET_OPTION':
      return setOption(createSurveyState, option, optionIndex);
    case 'SET_QUESTION':
      return setQuestion(createSurveyState, question);
    default:
      return createSurveyState;
  }
};

const setImageLink = (state: ICreateSurveyState, imageLink: string): ICreateSurveyState => ({
  ...state,
  imageLink,
});

const setImageFile = (state: ICreateSurveyState, selectedImageFile: File): ICreateSurveyState => {
  const selectedImage = URL.createObjectURL(selectedImageFile);

  return {
    ...state,
    selectedImage,
  };
};

const showErrorTip = (state: ICreateSurveyState, tipMessage: string): ICreateSurveyState => ({
  ...state,
  tipMessage,
  showTip: true,
});

const closeErrorTip = (state: ICreateSurveyState): ICreateSurveyState => ({
  ...state,
  showTip: false,
});

const setLoadingImage = (state: ICreateSurveyState, loadingImage: boolean) => ({
  ...state,
  loadingImage,
});

const setSurveyStart = (state: ICreateSurveyState, surveyStart: number) => ({
  ...state,
  surveyStart,
});

const setSurveyEnd = (state: ICreateSurveyState, surveyEnd: number) => ({
  ...state,
  surveyEnd,
});

const setSurveyMinVotes = (state: ICreateSurveyState, surveyMinVotes: number) => ({
  ...state,
  surveyMinVotes,
});

const setSurveyMaxVotes = (state: ICreateSurveyState, surveyMaxVotes: number) => ({
  ...state,
  surveyMaxVotes,
});

const setSurveyReward = (state: ICreateSurveyState, surveyReward: number) => ({
  ...state,
  surveyReward,
});

const setOption = (state: ICreateSurveyState, option: string, optionIndex: number) => ({
  ...state,
  firstOption: optionIndex === 0 ? option : state.firstOption,
  secondOption: optionIndex === 1 ? option : state.secondOption,
  thirdOption: optionIndex === 2 ? option : state.thirdOption,
  fourthOption: optionIndex === 3 ? option : state.fourthOption,
});

const setQuestion = (state: ICreateSurveyState, question: string) => ({
  ...state,
  question,
});

const initialState: ICreateSurveyState = {
  imageLink: '',
  selectedImage: '',
  tipMessage: '',
  showTip: false,
  loadingImage: false,
  surveyStart: 0,
  surveyEnd: 0,
  surveyMinVotes: 10,
  surveyMaxVotes: 100,
  surveyReward: 1000,
  firstOption: '',
  secondOption: '',
  thirdOption: '',
  fourthOption: '',
  question: '',
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [createSurveyState, createSurveyDispatch] = useReducer(stateReducer, initialState);

  return (
    <CreateSurveyContext.Provider value={{ createSurveyState, createSurveyDispatch }}>
      { children }
    </CreateSurveyContext.Provider>
  );
};

export const useCreateSurveyState = () => {
  const context = useContext(CreateSurveyContext);

  if (!context) throw new Error('useCreateSurveyState must be used in a create survey state provider');

  return context;
};

export default StateProvider;
