/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
// styles
import {
  CreationSurveyItemContainer,
  SurveyItemTitle,
} from 'styles/desktop/StyledPersonalOffice';
// components
import CompareSurvey from './survey_types/CompareSurvey';
import SurveySwitcher from './survey_types/SurveySwitcher';

const SurveyConditions: React.FC = (): ReactElement => (
  <CreationSurveyItemContainer>
    <SurveyItemTitle>2. Conditions</SurveyItemTitle>
    <CompareSurvey />
    <SurveySwitcher />
  </CreationSurveyItemContainer>
);

export default SurveyConditions;
