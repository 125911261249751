import React, { ReactElement } from 'react';
import {
  ISurvey,
} from 'interfaces/state.d';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import { usePrivateOfficeState } from 'context/privateOfficeState';
// styles
import {
  UnlockButtonContainer,
  UnlockButtonContent,
} from 'styles/desktop/StyledPersonalOffice';
// components
import Button from 'components/common/CustomButton';
import Pagination from 'components/desktop/personal_office/ContentPagination';
import Table from './Table';

const TableContainer: React.FC = (): ReactElement => {
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();
  const { privateOfficeState, privateOfficeDispatch } = usePrivateOfficeState();

  const unlockTokens = async () => {
    if (state.contract && state.contractWithSigner && state.account) {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      await state.contractWithSigner.unlockVoterTokens();
      state.contract.once(state.contract.filters.UnlockedTokens(state.account), () => {
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
        dispatch({
          type: 'SET_USER_LOCKED_TOKENS',
          payload: {
            lockedTokens: 0,
          },
        });
      });
    }
  };

  const tokensHandler = () => {
    if (state.lockedTokens) {
      unlockTokens();
    } else {
      modalDispatch({ type: 'OPEN_LOCK_MODAL' });
    }
  };

  const changePage = (newPage: number) => {
    privateOfficeDispatch({
      type: 'SET_COMPLETED_SURVEYS_PAGE',
      payload: {
        completedSurveysPage: newPage,
      },
    });
  };

  return (
    <>
      <Table />
      <UnlockButtonContainer>
        <Button secondary onClick={tokensHandler}>
          <UnlockButtonContent>
            {
              state.lockedTokens
                ? 'unlock pros'
                : 'lock and start'
            }
          </UnlockButtonContent>
        </Button>
      </UnlockButtonContainer>
      {
        privateOfficeState.completedSurveys
        && privateOfficeState.completedSurveys.length > 0
        && (
          <Pagination
            total={privateOfficeState.completedSurveys.length}
            changePage={changePage}
          />
        )
      }
    </>
  );
};

export default TableContainer;
