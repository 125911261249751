import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import axios from 'axios';
import {
  Grid,
  CircularProgress,
} from '@mui/material';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { useModalState } from 'context/modalState';
import { useGlobalState } from 'context/globalState';
import {
  ISurveyNft,
} from 'interfaces/state.d';
// styles
import StyledSurveyContainer, {
  NftContainer,
  VersusBlock,
  ProgressContainer,
  ProgressTitle,
  DeadLineTitle,
  ProgressBarContainer,
  ProgressContent,
  ProgressBarHint,
  LoaderContainer,
} from 'styles/desktop/StyledSurvey';
// components
import BoldTitle from 'components/common/BoldTitle';
import Button from 'components/common/CustomButton';
import CountDown from 'components/common/CountDown';

interface IParams {
  id: string;
  blockchainId: string;
}

const initialSurvey = [
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
  {
    id: 0,
    nftUrl: '',
    weight: 0,
  },
];
const initialSurveyState = [
  [1, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 1, 3, 4, 5, 6, 7, 8, 9],
  [0, 1, 2, 4, 5, 6, 7, 8, 9],
  [0, 1, 2, 3, 5, 6, 7, 8, 9],
  [0, 1, 2, 3, 4, 6, 7, 8, 9],
  [0, 1, 2, 3, 4, 5, 7, 8, 9],
  [0, 1, 2, 3, 4, 5, 6, 8, 9],
  [0, 1, 2, 3, 4, 5, 6, 7, 9],
  [0, 1, 2, 3, 4, 5, 6, 7, 8],
];

const DefaultSurvey: React.FC = (): ReactElement => {
  const [firstNft, setFirstNft] = useState<number>(-1);
  const [secondNft, setSecondNft] = useState<number>(-1);
  const [questionsAmount, setQuestionsAmount] = useState<number>(45);
  const [completeProgress, setCompleteProgress] = useState<number>(0);
  const [surveyState, setSurveyState] = useState<Array<Array<number>>>(initialSurveyState);
  const [surveyNfts, setSurveyNfts] = useState<Array<ISurveyNft> | null>(null);
  const [loading, setLoading] = useState(false);
  const [survey, setSurvey] = useState<typeof initialSurvey>(initialSurvey);
  // Yeah, right now it is set to be only one
  const [animatedNftIndex, setAnimatedNftIndex] = useState<number | null>(null);
  const [startTimestamp, setStartTimestamp] = useState<number>(0);
  const {
    id,
    blockchainId,
  }: IParams = useParams();

  const history = useHistory();
  const { modalDispatch } = useModalState();
  const { state, dispatch } = useGlobalState();

  const getRandomIndex = (array: Array<Array<number>>): number => {
    const randomIndex = Math.floor(Math.random() * array.length);
    if (!surveyState[randomIndex].length) {
      return surveyState.findIndex((items) => items.length);
    }
    return randomIndex;
  };

  const getRandomElement = (array: Array<number>): number => (
    array[Math.floor(Math.random() * array.length)]
  );

  const completeSurvey = useCallback(async () => {
    if (state.contract && state.contractWithSigner) {
      const surveyDuration = (Date.now() - startTimestamp) / 1000;
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      const answer = survey.map((surveyItem) => ({
        id: surveyItem.id,
        weight: surveyItem.weight,
      }));

      try {
        await state.contractWithSigner.vote(JSON.stringify(answer), blockchainId);
      } catch (e) {
        // eslint-disable-next-line
        console.error('Something went wrong while voting', e);
        return {
          success: false,
          surveyDuration,
          errorMessage: e,
        };
      }

      state.contract.once(state.contract.filters.NewVote(state.account), () => {
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
        modalDispatch({
          type: 'OPEN_FINISHED_SURVEY',
        });
        history.push('/');
      });

      return {
        success: true,
        surveyDuration,
      };
    }
    return {};
  }, [state.contract, state.account, survey]);

  const getSurveyNfts = useCallback(async () => {
    try {
      const surveyNftsResponse = await axios.get(`${state.baseUrl}/survey/${id}/nfts`);
      if (surveyNftsResponse.data) {
        setSurveyNfts(surveyNftsResponse.data);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Something went wrong while getting survey NFTs', e);
    }
  }, [state.baseUrl]);

  const findAnimatedNftIndex = (): number | undefined => surveyNfts?.findIndex((nft) => nft.imageUrl.includes('generator.artblocks.io'));

  useEffect(() => {
    setLoading(false);
    setFirstNft(-1);
    if (surveyState.every((items) => items.length === 0)) {
      completeSurvey().then((result) => {
        if (Object.prototype.hasOwnProperty.call(result, 'success')) {
          dispatch({
            type: 'LOG_AMPLITUDE_ACTION',
            payload: {
              actionName: 'survey vote',
              amplitudeProps: {
                surveyId: blockchainId,
                surveyDuration: result.surveyDuration,
                success: result.success,
                errorMessage: result.errorMessage,
              },
            },
          });
        }
      });
    }
  }, [surveyState]);

  useEffect(() => {
    if (firstNft < 0) {
      setFirstNft(getRandomIndex(surveyState));
    }
  }, [firstNft, surveyState, questionsAmount]);

  useEffect(() => {
    if (firstNft > -1) {
      setSecondNft(getRandomElement(surveyState[firstNft]));
    } else {
      setSecondNft(-1);
    }
  }, [firstNft]);

  useEffect(() => {
    if (!surveyNfts) {
      getSurveyNfts().then();
    } else {
      setSurvey(
        surveyNfts.map(
          (surveyItem) => ({
            id: surveyItem.idInSurvey,
            nftUrl: surveyItem.imageUrl,
            weight: 0,
          }),
        ),
      );
      const nftIndex = findAnimatedNftIndex();
      if (typeof nftIndex === 'number' && nftIndex > -1) {
        setAnimatedNftIndex(nftIndex);
      }
    }
  }, [surveyNfts]);

  const vote = async (choice: number) => {
    await setLoading(true);
    const tmp = [...surveyState];
    tmp[firstNft] = tmp[firstNft].filter(
      (item) => item !== secondNft,
    );
    tmp[secondNft] = tmp[secondNft].filter(
      (item) => item !== firstNft,
    );
    await setSurveyState(tmp);
    const tmpSurvey = [...survey];
    tmpSurvey[choice].weight += 1;
    setSurvey(tmpSurvey);
    await setQuestionsAmount(questionsAmount - 1);
    await setLoading(false);
  };

  useEffect(() => {
    setCompleteProgress(Math.floor((1 - ((questionsAmount) / 45)) * 100));
  }, [questionsAmount]);

  const autoChoose = useCallback(async () => {
    if (firstNft > -1 && secondNft > -1
      && (surveyState[firstNft].length < 5
        || surveyState[secondNft].length < 5) && surveyState[firstNft].includes(secondNft)) {
      const diff = survey[firstNft].weight - survey[secondNft].weight;
      if (diff < -4) {
        await vote(secondNft);
      } else if (diff > 4) {
        await vote(firstNft);
      }
    }
  }, [firstNft, secondNft, surveyState]);

  useEffect(() => {
    if (firstNft > -1 && !loading) {
      autoChoose();
    }
  }, [firstNft, loading]);

  useEffect(() => {
    setStartTimestamp(Date.now());
  }, []);

  return (
    <Grid container justifyContent="center">
      <StyledSurveyContainer>
        <BoldTitle placement="header">Discover and Vote for more valuable p2e NFT</BoldTitle>
        <Grid container justifyContent="center">
          <Grid item>
            <NftContainer onClick={() => vote(firstNft)}>
              {
                survey.map((nft, index) => (
                  !nft.nftUrl.includes('generator.artblocks.io')
                  && <img src={nft.nftUrl} hidden={firstNft !== index} alt="nft" />
                ))
              }
              {
                firstNft === animatedNftIndex
                && (
                  <iframe
                    title="nft"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    sandbox="allow-scripts"
                    src={survey[animatedNftIndex].nftUrl}
                  />
                )
              }
              {
                firstNft < 0
                  ? (
                    <LoaderContainer>
                      <CircularProgress size={100} />
                    </LoaderContainer>
                  )
                  : (
                    <Button
                      disabledButton={loading}
                    >
                      Vote
                    </Button>
                  )
              }
            </NftContainer>
          </Grid>
          <Grid item>
            <VersusBlock>VS</VersusBlock>
          </Grid>
          <Grid item>
            <NftContainer onClick={() => vote(secondNft)}>
              {
                survey.map((nft, index) => (
                  !nft.nftUrl.includes('generator.artblocks.io')
                  && <img src={nft.nftUrl} hidden={secondNft !== index} alt="nft" />
                ))
              }
              {
                secondNft === animatedNftIndex
                && (
                <iframe
                  title="nft"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  sandbox="allow-scripts"
                  src={survey[animatedNftIndex].nftUrl}
                />
                )
              }
              {
                firstNft < 0
                  ? (
                    <LoaderContainer>
                      <CircularProgress size={100} />
                    </LoaderContainer>
                  )
                  : (
                    <Button
                      disabledButton={loading}
                    >
                      Vote
                    </Button>
                  )
              }
            </NftContainer>
          </Grid>
        </Grid>
        <ProgressContainer>
          <Grid container justifyContent="space-between">
            <Grid item xs="auto">
              <ProgressTitle>progress of votes</ProgressTitle>
            </Grid>
            {/* <Grid item xs="auto" container direction="column" justifyContent="center"> */}
            {/*  <DeadLineTitle>Time until the end of the survey</DeadLineTitle> */}
            {/*  <CountDown deadline={new Date(Date.now() + 3600 * 1000)} /> */}
            {/* </Grid> */}
          </Grid>
          <ProgressBarContainer>
            <ProgressContent progress={completeProgress} />
          </ProgressBarContainer>
          <ProgressBarHint progress={completeProgress}>{`${completeProgress} % completed`}</ProgressBarHint>
        </ProgressContainer>
      </StyledSurveyContainer>
    </Grid>
  );
};

export default DefaultSurvey;
