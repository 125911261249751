import React, {
  ChangeEvent,
  ReactElement,
  useState,
} from 'react';
import axios from 'axios';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';

// components
import Button from 'components/common/CustomButton';
import SentSurveyRequestModal from 'components/modals/desktop/SentSurveyRequest';

// styles
import {
  ContainerOfTheContainer,
  Content,
  StyledAdminContainer,
} from 'styles/desktop/StyledAdmin';
import {
  FeedbackForm,
  Label,
  FeedbackRow,
  StyledTextField,
} from 'styles/desktop/StyledFeedback';

const FeedbackContainer: React.FC = (): ReactElement => {
  const [nftLink, setNftLink] = useState<string>('');
  const [rewards, setRewards] = useState<number>(100);
  const [minParticipants, setMinParticipants] = useState<number>(10);
  const [contact, setContact] = useState<string>('');

  const { state } = useGlobalState();
  const { modalDispatch } = useModalState();

  const requestSurvey = async () => {
    try {
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      const response = await axios.post(`${state.baseUrl}/survey-request/create`, {
        nftUrl: nftLink,
        rewardAmount: rewards,
        minVotes: minParticipants,
        contacts: contact,
      });

      if (response.data) {
        modalDispatch({
          type: 'OPEN_SENT_SURVEY_REQUEST',
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while sending a request: ', e);
    }
    modalDispatch({
      type: 'CLOSE_LOADING_MODAL',
    });
  };

  const changeNftLink = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (value.length < 351) {
      setNftLink(value);
    }
  };

  const changeRewards = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (value.length < 8) {
      setRewards(+value);
    }
  };

  const changeMinParticipants = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (value.length < 8) {
      setMinParticipants(+value);
    }
  };

  const changeContact = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (value.length < 255) {
      setContact(value);
    }
  };

  return (
    <ContainerOfTheContainer container justifyContent="center">
      <StyledAdminContainer>
        <Content container justifyContent="center" alignItems="center">
          <FeedbackForm>
            <FeedbackRow>
              <Label htmlFor="nft_link">NFT link</Label>
              <StyledTextField
                id="nft_link"
                fullWidth
                value={nftLink}
                onChange={changeNftLink}
              />
            </FeedbackRow>
            <FeedbackRow>
              <Label htmlFor="rewards">Rewards amount</Label>
              <StyledTextField
                id="rewards"
                fullWidth
                type="number"
                value={rewards}
                onChange={changeRewards}
              />
            </FeedbackRow>
            <FeedbackRow>
              <Label htmlFor="min_participants">Minimum participants amount</Label>
              <StyledTextField
                id="min_participants"
                fullWidth
                type="number"
                value={minParticipants}
                onChange={changeMinParticipants}
              />
            </FeedbackRow>
            <FeedbackRow>
              <Label htmlFor="contact">Contact for communication</Label>
              <StyledTextField
                id="contact"
                fullWidth
                value={contact}
                onChange={changeContact}
              />
            </FeedbackRow>
            <Button onClick={requestSurvey}>Send</Button>
          </FeedbackForm>
        </Content>
      </StyledAdminContainer>
      <SentSurveyRequestModal />
    </ContainerOfTheContainer>
  );
};

export default FeedbackContainer;
