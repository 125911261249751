import React, { createContext, useContext, useReducer } from 'react';
import {
  IActiveSurveysState,
  IActiveSurvey,
} from 'interfaces/state.d';

type ActionType = 'SET_SURVEYS';
type PayloadType = any;
interface IAction {
  type: ActionType;
  payload?: PayloadType;
}
type DispatchType = (action: IAction) => void;

export const ActiveSurveysContext = createContext<{activeSurveysState: IActiveSurveysState;
  activeSurveysDispatch: DispatchType} | undefined>(undefined);

const stateReducer = (activeSurveysState: IActiveSurveysState, action: IAction) => {
  const {
    surveys,
  } = action.payload;

  switch (action.type) {
    case 'SET_SURVEYS':
      return setSurveys(activeSurveysState, surveys);
    default:
      return activeSurveysState;
  }
};

const setSurveys = (state: IActiveSurveysState,
  surveys: Array<IActiveSurvey>): IActiveSurveysState => ({
  ...state,
  surveys,
});

const initialState: IActiveSurveysState = {
  surveys: null,
};

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeSurveysState, activeSurveysDispatch] = useReducer(stateReducer, initialState);

  return (
    <ActiveSurveysContext.Provider value={{ activeSurveysState, activeSurveysDispatch }}>
      { children }
    </ActiveSurveysContext.Provider>
  );
};

export const useActiveSurveysState = () => {
  const context = useContext(ActiveSurveysContext);

  if (!context) throw new Error('useActiveSurveysState must be used in a active surveys state provider');

  return context;
};

export default StateProvider;
