import React, {
  ReactElement,
  useState,
} from 'react';
import {
  TextField,
} from '@mui/material';
import axios from 'axios';
import CreateSurveyProvider from 'context/createSurveyState';
import PrivateOfficeContext from 'context/privateOfficeState';
import { useGlobalState } from 'context/globalState';
// styles
import {
  StyledAdminContainer,
  ContainerOfTheContainer,
  Content,
  LoginForm,
} from 'styles/desktop/StyledAdmin';
// components
import Button from 'components/common/CustomButton';
import CreateSurvey from 'components/desktop/personal_office/create_survey/CreateSurveyContainer';
import CreatedSurveys from 'components/desktop/personal_office/created_surveys/CreatedSurveysContainer';

const AdminContainer: React.FC = (): ReactElement => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [logged, setLogged] = useState<boolean>(false);

  const { state } = useGlobalState();

  const loginAction = async () => {
    try {
      const authResponse = await axios.post(`${state.baseUrl}/auth/login`, {
        sender: login,
        password,
      });

      if (authResponse.data) {
        setLogged(true);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error('Something went wrong while logging ', e);
    }
  };

  return (
    <ContainerOfTheContainer container justifyContent="center">
      <StyledAdminContainer>
        <Content container justifyContent="center" alignItems="center">
          {
            logged
              ? (
                <>
                  <CreateSurveyProvider>
                    <CreateSurvey />
                  </CreateSurveyProvider>
                  <PrivateOfficeContext>
                    <CreatedSurveys />
                  </PrivateOfficeContext>
                </>
              )
              : (
                <LoginForm>
                  <TextField
                    fullWidth
                    value={login}
                    onChange={(e) => setLogin(e.currentTarget.value)}
                  />
                  <TextField
                    fullWidth
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                  <Button onClick={loginAction}>Login</Button>
                </LoginForm>
              )
          }
        </Content>
      </StyledAdminContainer>
    </ContainerOfTheContainer>
  );
};

export default AdminContainer;
