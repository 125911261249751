import React, { ReactElement, useState } from 'react';
// styles
import {
  SwitcherContainer,
} from 'styles/desktop/StyledPersonalOffice';
// components
import Button from 'components/common/CustomButton';
import FourChoiceSurvey from './FourChoiceSurvey';

const SurveySwitcher: React.FC = (): ReactElement => {
  const [isNotDefault, setIsNotDefault] = useState<boolean>(false);

  return (
    isNotDefault
      ? <FourChoiceSurvey />
      : (
        <SwitcherContainer>
          <Button onClick={() => setIsNotDefault(true)}>Add options</Button>
        </SwitcherContainer>
      )
  );
};

export default SurveySwitcher;
