import React, {
  ReactElement, useEffect, useState, useCallback,
} from 'react';
import { ethers } from 'ethers';
import
{
  Switch,
  Route,
} from 'react-router-dom';
import { ContractInterface } from '@ethersproject/contracts';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
import abi from 'static/abi/contract.abi.json';
import axios from 'axios';
import { HubConnectionBuilder } from '@microsoft/signalr';
import {
  IFeed,
  IToken,
} from 'interfaces/common.d';
import { getNetworksIds } from 'networksHelper';
import LoadingModal from 'components/modals/desktop/LoadingModal';
import WrongNetworkModal from 'components/modals/mobile/WrongNetworkModal';
import OpenedSurveys from './opened_surveys/OpenedSurveysContainer';

const MobileContainer: React.FC = (): ReactElement => {
  const [accounts, setAccounts] = useState<Array<string> | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const [address, setAddress] = useState<string>('');
  const [appInitialized, setAppInitialized] = useState<boolean>(false);
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();
  const networkId = state.currentNetworkId;

  useEffect(() => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'votes opened main page',
      },
    });
  }, []);

  const getAddress = useCallback(async (baseUrl: string): Promise<void> => {
    const networkProviderResponse = await axios.get(`${baseUrl}/network-provider/${networkId}`);
    setAddress(networkProviderResponse.data.contractAddress as string);
  }, [networkId]);

  const getBalance = useCallback(async () => {
    const knownNetworksIds = getNetworksIds();
    if (accounts && state.tokenContract
      && state.currentNetworkId && knownNetworksIds.includes(state.currentNetworkId)) {
      const balanceResponse = await state.tokenContract.balanceOf(accounts[0]);
      setBalance(+ethers.utils.formatEther(balanceResponse));
    }
  }, [accounts, state.tokenContract, state.currentNetworkId]);

  const initContract = useCallback(() => {
    if (!state.networkProvider || !address || !abi || !accounts || !accounts.length) {
      throw new Error('Unable to initialize contract');
    }

    dispatch({
      type: 'INIT_CONTRACT',
      payload: {
        address,
        abi,
        signer: state.networkProvider.getUncheckedSigner(accounts[0]),
      },
    });
  }, [state.networkProvider, address, abi, accounts]);

  const connect = useCallback(async (): Promise<void> => {
    if (!(window.ethereum && (window.ethereum.isMetaMask || window.ethereum.isTrustWallet))) {
      window.location.href = 'https://metamask.io';
      return;
    }

    let accountList: Array<string> = [];
    if (state.networkProvider) {
      const knownNetworksIds = getNetworksIds();
      const metamaskNetworkId = +window.ethereum.chainId;
      if (knownNetworksIds.includes(metamaskNetworkId)) {
        dispatch({
          type: 'SET_CURRENT_NETWORK',
          payload: {
            ...state,
            currentNetworkId: metamaskNetworkId,
          },
        });
      } else {
        modalDispatch({ type: 'OPEN_WRONG_NETWORK_MODAL' });
        return;
      }
      accountList = await state.networkProvider.listAccounts();
    }

    if (accountList.length < 1) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.location.reload();
    } else {
      setAccounts(accountList);
    }
  }, [state.networkProvider, networkId, modalDispatch]);

  const checkUserLockedTokens = useCallback(async () => {
    if (state.contract && state.account) {
      const stakedAmount = await state.contract.userTokensLockedAmount(state.account);
      dispatch({
        type: 'SET_USER_LOCKED_TOKENS',
        payload: {
          lockedTokens: +ethers.utils.formatEther(stakedAmount),
        },
      });
    }
  }, [state.contract, state.account]);

  useEffect(() => {
    setAddress('');
    setAppInitialized(false);
  }, [state.currentNetworkId]);

  // getting balance only when user have at least 1 connected account
  useEffect(() => {
    if (accounts && accounts.length) {
      getBalance();
    }
  }, [accounts, getBalance]);

  // init contract if only we have user account address
  useEffect(() => {
    if (accounts && address) {
      initContract();
    }
  }, [accounts, address]);

  useEffect(() => {
    if (state.account && state.networkProvider && !state.tokenContract) {
      dispatch({
        type: 'INIT_TOKEN_CONTRACT',
        payload: {
          ...state,
          tokenAddress: state.tokenContractAddress,
        },
      });
    }
  }, [state]);

  useEffect(() => {
    checkUserLockedTokens();
  }, [checkUserLockedTokens]);

  useEffect(() => {
    if (accounts) {
      dispatch({ type: 'SET_ACCOUNT', payload: { account: accounts[0] } });
    }
  }, [accounts]);

  useEffect(() => {
    if (typeof balance === 'number') {
      dispatch({ type: 'SET_BALANCE', payload: { balance } });
    }
  }, [balance]);

  useEffect(() => {
    if (!appInitialized && abi && address
      && networkId) {
      dispatch({
        type: 'INITIALIZE_APPLICATION',
        payload: {
          abi,
          address,
          currentNetworkId: networkId,
        },
      });
      setAppInitialized(true);
    }
  }, [appInitialized, address, networkId]);

  useEffect(() => {
    if (!address) {
      getAddress(state.baseUrl);
    }
  }, [address,
    state, networkId, appInitialized]);

  // call connect only if application is initialized, provider exists and accounts is not set yet
  useEffect(() => {
    if (appInitialized && !accounts && state.networkProvider) {
      connect();
    }
  }, [appInitialized, state.networkProvider, accounts, connect]);

  // useEffect(() => {
  //   if (!state.hubConnection) {
  //     const hubConnection = new HubConnectionBuilder()
  //       .withUrl(`${state.baseUrl}/event-hub`)
  //       .build();
  //
  //     dispatch({ type: 'SET_HUB_CONNECTION', payload: { hubConnection } });
  //
  //     hubConnection.start();
  //   }
  // }, [state.hubConnection, state.baseUrl, dispatch]);

  return (
    <>
      <Switch>
        <Route exact path="/">
          <OpenedSurveys />
        </Route>
      </Switch>
      <LoadingModal />
      <WrongNetworkModal />
    </>
  );
};

export default MobileContainer;
