import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as ArrowInputToConditions } from 'static/images/arrow_input_conditions.svg';
import { ReactComponent as ArrowConditionsToReward } from 'static/images/arrow_conditions_reward.svg';
// styles
import {
  CreationSurveyContainer,
  ArrowInputToConditionsContainer,
  ArrowConditionsToRewardContainer,
} from 'styles/desktop/StyledPersonalOffice';
// components
import BoldTitle from 'components/common/BoldTitle';
import Snack from './CreateSurveySnackbar';
import NftInput from './NftInput';
import Conditions from './SurveyConditions';
import Rewards from './SurveyRewards';

const CreateSurveyContainer: React.FC = (): ReactElement => (
  <>
    <BoldTitle placement="header">Create survey</BoldTitle>
    <Grid container justifyContent="center">
      <CreationSurveyContainer>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <NftInput />
            <ArrowInputToConditionsContainer>
              <ArrowInputToConditions />
            </ArrowInputToConditionsContainer>
            <Snack />
          </Grid>
          <Grid item xs={4}>
            <Conditions />
          </Grid>
          <Grid item xs={4}>
            <Rewards />
            <ArrowConditionsToRewardContainer>
              <ArrowConditionsToReward />
            </ArrowConditionsToRewardContainer>
          </Grid>
        </Grid>
      </CreationSurveyContainer>
    </Grid>
  </>
);

export default CreateSurveyContainer;
