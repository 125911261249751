import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import PrivateOfficeContext from 'context/privateOfficeState';
// styles
import StyledPersonalOfficeContainer from 'styles/desktop/StyledPersonalOffice';
// context
import CreateSurveyProvider from 'context/createSurveyState';
// components
import CreateSurvey from './create_survey/CreateSurveyContainer';
import CompletedSurveys from './completed_surveys/CompletedSurveysContainer';
import CreatedSurveys from './created_surveys/CreatedSurveysContainer';

const PersonalOfficeContainer: React.FC = (): ReactElement => (
  <Grid container justifyContent="center">
    <StyledPersonalOfficeContainer>
      <PrivateOfficeContext>
        {/* <CreateSurveyProvider> */}
        {/*  <CreateSurvey /> */}
        {/* </CreateSurveyProvider> */}
        <CompletedSurveys />
        <CreatedSurveys />
      </PrivateOfficeContext>
    </StyledPersonalOfficeContainer>
  </Grid>
);

export default PersonalOfficeContainer;
