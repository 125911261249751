import styled from 'styled-components';
import { TextField } from '@mui/material';
import {
  LoginForm,
} from './StyledAdmin';

export const FeedbackForm = styled(LoginForm)`
  height: 500px;
  margin: 20px 0;
`;

export const StyledTextField = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
`;

export const FeedbackRow = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  color: white;
`;
