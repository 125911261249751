import styled from 'styled-components';
import {
  Paper,
  Modal,
  Grid,
} from '@mui/material';
import bg from 'static/images/finished_survey_bg.svg';

export const ModalContainer = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    width: 924px;
    height: 392px;
    border-radius: 16px;
    outline: none;
    background: url(${bg}) 0 0, #1A1A39;
    background-repeat: no-repeat;
  }
`;

export const ModalContent = styled(Grid)`
  height: 100%;
`;

export const ModalTitle = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.03em;
`;

export const ButtonContainer = styled.div`
  width: 338px;
  margin-top: 32px;
  
  a {
    text-decoration: none;
  }
`;

export const ModalButtonContent = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`;
