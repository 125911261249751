import React, { ReactElement, useState, useEffect } from 'react';
import {
  Fade,
  Grid,
} from '@mui/material';
import {
  Link,
} from 'react-router-dom';
import { useModalState } from 'context/modalState';
// components
import Button from 'components/common/CustomButton';
// styles
import {
  ModalContainer,
  StyledPaper,
  ModalContent,
  ModalTitle,
  ModalButtonContent,
  ButtonContainer,
} from 'styles/modals/StyledFinishedSurveyModal';

const FinishedSurveyModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  const { modalsState, modalDispatch } = useModalState();

  const handleClose = () => {
    modalDispatch({ type: 'CLOSE_FINISHED_SURVEY' });
  };

  useEffect(() => {
    setOpen(modalsState.openedFinishedSurveyModal);
  }, [modalsState.openedFinishedSurveyModal]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledPaper>
          <ModalContent container justifyContent="center" alignItems="center" direction="column">
            <Grid item>
              <ModalTitle>Well done, you passed the survey</ModalTitle>
            </Grid>
            <Grid item>
              In your personal account, you can track the status of the survey
            </Grid>
            <Grid item>
              <ButtonContainer>
                <Link to="/po" onClick={handleClose}>
                  <Button>
                    <ModalButtonContent>
                      view completed
                    </ModalButtonContent>
                  </Button>
                </Link>
              </ButtonContainer>
            </Grid>
          </ModalContent>
        </StyledPaper>
      </Fade>
    </ModalContainer>
  );
};

export default FinishedSurveyModal;
