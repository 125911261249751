import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const StyledCount = styled.div`
    font-weight: bold;
    font-size: ${isMobile ? 14 : 16}px;
    line-height: 30px;
    color: #fd0;
`;

export const StyledCountItem = styled.span<{time: string}>`
  color: ${(props) => (props.time === '00' ? 'black' : '#5900F7')}
`;

export default StyledCount;
