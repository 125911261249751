import React, { ReactElement, useState, useEffect } from 'react';
import { useModalState } from 'context/modalState';
import {
  Fade,
} from '@mui/material';
// styles
import {
  ModalContainer,
  StyledNftImage,
} from 'styles/modals/StyledNftImageModal';

const NftImageModal: React.FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const { modalsState, modalDispatch } = useModalState();

  const handleClose = () => {
    modalDispatch({
      type: 'CLOSE_NFT_IMAGE_MODAL',
    });
  };

  useEffect(() => {
    setOpen(modalsState.openedNftImageModal);
  }, [modalsState.openedNftImageModal]);

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <StyledNftImage src={modalsState.nftImageUrl} alt="nft" />
      </Fade>
    </ModalContainer>
  );
};

export default NftImageModal;
