import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { useGlobalState } from 'context/globalState';
import SurveySwitcher from './SurveySwitcher';

const SurveyContainer: React.FC = (): ReactElement => {
  const { state } = useGlobalState();

  return (
    state.lockedTokens ? <SurveySwitcher /> : <Redirect to="/" />
  );
};

export default SurveyContainer;
