import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
} from '@mui/material';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { useGlobalState } from 'context/globalState';
import { useModalState } from 'context/modalState';
// styles
import StyledSurveyContainer, {
  NftContainer,
  SurveyQuestion,
  OptionsContainer,
} from 'styles/desktop/StyledSurvey';
// components
import BoldTitle from 'components/common/BoldTitle';
import Button from 'components/common/CustomButton';

interface IProps {
  options: Array<string>;
  nftUrl: string;
}

interface IParams {
  id: string;
  blockchainId: string;
}

interface IAmplitudeEventProps {
  surveyDuration: number;
  success: boolean;
  errorMessage?: any;
}

const FourOptionsSurvey: React.FC<IProps> = (props: IProps): ReactElement => {
  const {
    options,
    nftUrl,
  } = props;
  const {
    blockchainId,
  }: IParams = useParams();
  const [startTimestamp, setStartTimestamp] = useState<number>(0);
  const history = useHistory();
  const { state, dispatch } = useGlobalState();
  const { modalDispatch } = useModalState();

  const emitAmplitudeAction = (result: IAmplitudeEventProps) => {
    dispatch({
      type: 'LOG_AMPLITUDE_ACTION',
      payload: {
        actionName: 'survey vote',
        amplitudeProps: {
          surveyId: blockchainId,
          surveyDuration: result.surveyDuration,
          success: result.success,
          errorMessage: result.errorMessage,
        },
      },
    });
  };

  const vote = async (optionIndex: number) => {
    if (state.contract && state.contractWithSigner) {
      const surveyDuration = (Date.now() - startTimestamp) / 1000;
      modalDispatch({
        type: 'OPEN_LOADING_MODAL',
      });
      const answer = optionIndex.toString();

      try {
        await state.contractWithSigner.vote(answer, blockchainId);
      } catch (e) {
        // eslint-disable-next-line
        console.error('Something went wrong while voting', e);
        emitAmplitudeAction({
          success: false,
          surveyDuration,
          errorMessage: e,
        });
      }

      state.contract.once(state.contract.filters.NewVote(state.account), () => {
        emitAmplitudeAction({
          success: true,
          surveyDuration,
        });
        modalDispatch({
          type: 'CLOSE_LOADING_MODAL',
        });
        modalDispatch({
          type: 'OPEN_FINISHED_SURVEY',
        });
        history.push('/');
      });
    }
  };

  useEffect(() => {
    setStartTimestamp(Date.now());
  }, []);

  return (
    <Grid container justifyContent="center">
      <StyledSurveyContainer>
        <BoldTitle placement="header">Discover and Vote for more valuable p2e NFT</BoldTitle>
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <NftContainer notDefault>
              <img src={nftUrl} alt="nft" />
            </NftContainer>
          </Grid>
          <Grid item xs={4}>
            <SurveyQuestion>How much will this NFT cost (U$DT) ?</SurveyQuestion>
            <OptionsContainer>
              {
                options.map((option, index) => (
                  <Button key={option} onClick={() => vote(index)}>{option}</Button>
                ))
              }
            </OptionsContainer>
          </Grid>
        </Grid>
      </StyledSurveyContainer>
    </Grid>
  );
};

export default FourOptionsSurvey;
