import React, { ReactElement, useEffect, useCallback } from 'react';
import axios from 'axios';
import { usePrivateOfficeState } from 'context/privateOfficeState';
import { useGlobalState } from 'context/globalState';
// styles
import {
  StyledCreatedSurveysContainer,
} from 'styles/desktop/StyledPersonalOffice';
// components
import BoldTitle from 'components/common/BoldTitle';
import Table from './TableContainer';

const CreatedSurveysContainer: React.FC = (): ReactElement => {
  const { privateOfficeState, privateOfficeDispatch } = usePrivateOfficeState();
  const { state } = useGlobalState();

  const getCreatedSurveys = useCallback(async () => {
    try {
      const createdSurveysResponse = await axios.get(`${state.baseUrl}/user/${state.account}/created-surveys`);
      if (createdSurveysResponse.data) {
        privateOfficeDispatch({
          type: 'SET_CREATED_SURVEYS',
          payload: {
            createdSurveys: createdSurveysResponse.data,
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Something went wrong while getting user created surveys');
    }
  }, [state.baseUrl, state.account]);

  useEffect(() => {
    if (state.account && !privateOfficeState.createdSurveys) {
      getCreatedSurveys().then();
    }
  }, [state.account, privateOfficeState.createdSurveys]);

  useEffect(() => {
    if (state.contract) {
      state.contract.on(state.contract.filters.NewSurvey(), () => {
        getCreatedSurveys().then();
      });
      state.contract.on(state.contract.filters.Claim(state.account), () => {
        getCreatedSurveys().then();
      });
      state.contract.on(state.contract.filters.NewVote(), () => {
        getCreatedSurveys().then();
      });
      state.contract.on(state.contract.filters.RewardsAreSet(), () => {
        getCreatedSurveys().then();
      });
    }
  }, [state.contract]);

  return (
    <StyledCreatedSurveysContainer>
      {
        privateOfficeState.createdSurveys
        && privateOfficeState.createdSurveys.length > 0
        && (
          <>
            <BoldTitle placement="table">Analytics of created surveys</BoldTitle>
            <Table />
          </>
        )
      }
    </StyledCreatedSurveysContainer>
  );
};

export default CreatedSurveysContainer;
